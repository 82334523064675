import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { openModal, closeModal } from "../../actions/modalActions";
import Modal from "../elements/Modal";
import ReceiptUploadForm from "./ReceiptUploadForm";
import useExpenseCreate from "../../actions/hooks/useExpenseCreate";
import useExpenseUpdate from "../../actions/hooks/useExpenseUpdate";
import useSupportingDocumentCreate from "../../actions/hooks/useSupportingDocumentCreate";
import useSupportingDocumentAnalyze from "../../actions/hooks/useSupportingDocumentAnalyze";
import Alert from "../elements/Alert";
import useShowAlert from "../../actions/hooks/utility/useShowAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { setRerender } from "../../actions/rerenderAction";

export default function ExpenseCreateOptionScan({ children }) {
  const dispatch = useDispatch();
  const [receipt, setReceipt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expenseId, setExpenseId] = useState(null);
  const [showFileSizeAlert, setShowFileSizeAlert] = useState(false);

  // Handle Open Modal to upload Receipt
  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalType: "static",
        modalTitle: "New Expense",
        key: "modalReceiptSubmit",
        needsDelete: false,
      })
    );
  };
  const modal = useSelector((store) => store.modal);

  // Set receipt state to handle later with api calls
  const uploadReceiptImage = (file) => {
    setReceipt(file);
  };

  const { expenseCreate, expenseCreateResponse } = useExpenseCreate();
  const { supportingDocumentCreate, supportingDocumentCreateResponse } = useSupportingDocumentCreate();
  const { supportingDocumentAnalyze, supportingDocumentAnalyzeResponse } = useSupportingDocumentAnalyze();
  const { expenseUpdate, expenseUpdateResponse } = useExpenseUpdate();
  const { showSuccessAlert, setShowSuccessAlert, showErrorAlert, setShowErrorAlert } = useShowAlert();

  const modalSubmit = () => {
    if (receipt) {
      expenseCreate();
    } else {
      setShowErrorAlert(true);
    }
  };

  // Create supporting document
  useEffect(() => {
    if (expenseCreateResponse) {
      setLoading(true);
      let formData = new FormData();
      formData.append("file", receipt);
      formData.append("name", receipt.name);
      formData.append("expense_id", expenseCreateResponse.data.expense.id);

      setExpenseId(expenseCreateResponse.data.expense.id);

      supportingDocumentCreate(formData);
    }
  }, [expenseCreateResponse]);

  // Analyze supporting document
  useEffect(() => {
    if (supportingDocumentCreateResponse) {
      setLoading(true);
      if (supportingDocumentCreateResponse.status === 200) {
        supportingDocumentAnalyze(
          supportingDocumentCreateResponse.data.supportingDocument.id
        );
        setShowSuccessAlert(true);
      } else {
        dispatch(closeModal());
        setShowFileSizeAlert(true);
        setLoading(false);
        dispatch(setRerender());
      }
    }
  }, [supportingDocumentCreateResponse, setShowSuccessAlert, setShowErrorAlert]);

  // Expense update
  useEffect(() => {
    if (
      supportingDocumentAnalyzeResponse &&
      supportingDocumentAnalyzeResponse.data &&
      supportingDocumentAnalyzeResponse.data.data
    ) {
      expenseUpdate(
        expenseId,
        supportingDocumentAnalyzeResponse.data.data.original.receipt_data[0]
      );
    }
  }, [supportingDocumentAnalyzeResponse]);

  useEffect(() => {
    if (expenseUpdateResponse && expenseUpdateResponse.status === 200) {
      if (expenseUpdateResponse.status === 200) {
        setShowSuccessAlert(true);
        dispatch(setRerender());
      } else {
        setShowErrorAlert(true);
      }

      setLoading(false);
      dispatch(closeModal());
    }
  }, [expenseUpdateResponse]);

  return (
    <>
      <div
        onClick={handleOpenModal}
        className="hover:text-primary-500 hover:cursor-pointer"
      >
        {children}
      </div>
      {modal.isOpen && modal.key === "modalReceiptSubmit" && (
        <Modal modalSubmit={modalSubmit}>
          {!loading && <ReceiptUploadForm onChange={uploadReceiptImage} />}
          {loading && (
            <div className="flex items-center justify-center">
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                className="text-blue-500 text-4xl"
              />
              <span className="ml-2">Loading...</span>
            </div>
          )}
        </Modal>
      )}
      {showErrorAlert && (
        <Alert
          message="Failed to create expense."
          type="error"
          duration={3000}
        />
      )}
      {showSuccessAlert && (
        <Alert
          message="Expense created successfully."
          type="success"
          duration={3000}
        />
      )}
      {showFileSizeAlert && (
        <Alert
          message="Please edit manually."
          type="warning"
          duration={3000}
        />
      )}
    </>
  );
}
