const initialState = { loading: false };

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_LOADER":
      return {
        loading: true,
      };
    case "STOP_LOADER":
      return initialState;
    default:
      return state;
  }
};

export default loaderReducer;
