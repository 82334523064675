// reducers/asideReducer.js

const initialState = {
  isOpen: true,
};

const asideReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TRIGGER_ASIDE":
      return {
        isOpen: !state.isOpen,
      };
    case "OPEN_ASIDE":
      return {
        isOpen: true,
      };
    case "CLOSE_ASIDE":
      return {
        isOpen: false,
      };
    default:
      return state;
  }
};

export default asideReducer;
