import { useState } from "react";
import axios from "axios";

const useReportExportCSV = () => {
  const [reportExportCSVResponse, setReportExportCSVResponse] = useState(null);

  const reportExportCSV = async (id) => {
    try {
      const response = await axios.get(`report/export/csv/${id}`);
      setReportExportCSVResponse(response);
      downloadCSV(response.data, `report_${Date.now()}.csv`);
    } catch (error) {
      // Do nothing
    }
  };

  const downloadCSV = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const downloadLink = document.createElement("a");

    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  return { reportExportCSV, reportExportCSVResponse, downloadCSV };
};

export default useReportExportCSV;
