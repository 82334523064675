// store.js
import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./reducers/modalReducer";
import expenseReducer from "./reducers/expenseReducer";
import receiptReducer from "./reducers/receiptReducer";
import reportReducer from "./reducers/reportReducer";
import rerenderReducer from "./reducers/rerenderReducer";
import asideReducer from "./reducers/asideReducer";
import loaderReducer from "./reducers/loaderReducer";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    expense: expenseReducer,
    receipt: receiptReducer,
    report: reportReducer,
    rerender: rerenderReducer,
    aside: asideReducer,
    loader: loaderReducer,
  },
});

export default store;
