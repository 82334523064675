import { useEffect, useState } from "react";
import InputField from "../layout/InputField";
import Alert from "../elements/Alert";
import axios from "axios";
import useFetchCompany from "../../actions/hooks/useFetchCompany";
import Button from "../elements/Button";
import LoadError from "../elements/LoadError";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
export default function CompanyForm() {
  const [loading, setLoading] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const { user } = useContext(UserContext);
  const isAdmin = user.user_roles.includes("administrator");
  const [form, setForm] = useState({
    vat: "",
    legal_name: "",
    profession: "",
    doy: "",
    address: "",
    phone: "",
  });

  // Render form input fields
  const renderInputFields = () => {
    const ignoredFields = ["id", "users", "created_at", "updated_at"];
    return Object.keys(form).map(
      (key) =>
        !ignoredFields.includes(key) && (
          <div key={key} className="mb-6 md:w-1/2 px-3">
            <InputField
              disabled={!isAdmin}
              label={
                key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")
              }
              type="text"
              required
              onChange={(e) => setForm({ ...form, [key]: e.target.value })}
              value={form[key]}
              readOnly={false}
            />
          </div>
        )
    );
  };

  // Use fetch company hook
  const companyData = useFetchCompany();

  useEffect(() => {
    if (companyData) {
      setForm(companyData);
      setLoading(false);
    }
  }, [companyData]);

  // Submit form
  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch("company/update", form);
      if (response.data.company) {
        setForm(response.data.company);
        setShowSuccessAlert(true);
      } else {
        setShowErrorAlert(true);
      }
    } catch (error) {
      setShowErrorAlert(true);
    }
  };

  // Automatically hide alerts
  useEffect(() => {
    const alertTimer = setTimeout(() => {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
    }, 2000);

    return () => {
      clearTimeout(alertTimer);
    };
  }, [showSuccessAlert, showErrorAlert]);

  if (loading) {
    return <LoadError />;
  }

  return (
    <div>
      <form
        className="w-full max-w-screen-md mx-auto md:flex md:flex-wrap"
        onSubmit={handleOnSubmit}
      >
        {renderInputFields()}
        {isAdmin && (
          <div className="px-3 w-full">
            <div className="flex flex-col items-end">
              <Button label="Update" />
            </div>
          </div>
        )}
      </form>
      {showErrorAlert && (
        <Alert
          message="Failed to update company details."
          type="error"
          duration={2000}
        />
      )}
      {showSuccessAlert && (
        <Alert
          message="Company updated successfully."
          type="success"
          duration={2000}
        />
      )}
    </div>
  );
}
