// reducers/modalReducer.js

const initialState = {
  isOpen: false,
  modalType: null,
  modalTitle: null,
  modalSubmit: null,
  needsDelete: true,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        isOpen: true,
        modalType: action.payload.modalType,
        modalTitle: action.payload.modalTitle,
        modalSubmit: action.payload.modalSubmit,
        needsDelete: action.payload.needsDelete,
        key: action.payload.key || null,
      };
    case "CLOSE_MODAL":
      return initialState;
    case "SUBMIT_MODAL":
      return state;
    case "SUBMIT_EXPENSE":
      return { ...state, isOpen: false };
    case "SUBMIT_RECEIPT":
      return { ...state, isOpen: false };
    case "SUBMIT_REPORT":
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default modalReducer;
