export default function SelectField({
  children,
  label,
  required,
  value,
  ...params
}) {
  const isValid = required && value === "";

  const classNames = `appearance-none block w-full focus:outline-none border rounded py-2 px-4 bg-white  ${
    isValid ? "border-red-500" : ""
  }`;

  return (
    <div>
      {label && (
        <label className="block uppercase tracking-wide text-xs font-bold mb-1">
          {label}
        </label>
      )}
      <select className={classNames} value={value} {...params}>
        {children}
      </select>
      {isValid && (
        <p className="text-red-500 text-xs italic">
          Please fill out this field.
        </p>
      )}
    </div>
  );
}
