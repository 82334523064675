import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useFetchUserReports = () => {
  const [reports, setReports] = useState();
  const rerender = useSelector((store) => store.rerender);
  const dispatch = useDispatch();
  const startTimeRef = useRef(null);

  useEffect(() => {
    const fetchUserReports = async () => {
      let hasError = false;
      dispatch(startLoader({}));
      startTimeRef.current = performance.now();
      try {
        const response = await axios.get("user/reports");
        setReports(response);
      } catch (error) {
        // setTimeout(() => dispatch(stopLoader({})), 5000);
        hasError = true;
      } finally {
        const endTime = performance.now();
        const elapsedTime = endTime - startTimeRef.current;

        if (elapsedTime < 500) {
          setTimeout(
            () => dispatch(stopLoader({})),
            hasError ? 4000 : 500 - elapsedTime
          );
        } else {
          dispatch(stopLoader({}));
        }
      }
    };

    fetchUserReports();
  }, [rerender]);

  return reports;
};

export default useFetchUserReports;
