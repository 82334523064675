import React from "react";

const OverlayLoader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-80 z-40">
      <div className="flex items-center">
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full border-t-2 border-primary-800 border-solid h-72 w-72"></div>
          <div className="absolute">
            <img
              src="/images/logo_expenses_blue.png"
              alt="Heartbeat"
              className="w-60 h-18 object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayLoader;
