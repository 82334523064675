import Modal from "../elements/Modal";
import ExpenseForm from "./ExpenseForm";
import { useDispatch } from "react-redux";
import { openModal, closeModal } from "../../actions/modalActions";
import { setExpense } from "../../actions/expenseAction";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Alert from "../elements/Alert";
import useShowAlert from "../../actions/hooks/utility/useShowAlert";
import useExpenseCreateUpdate from "../../actions/hooks/utility/useExpenseCreateUpdate";
import { setRerender } from "../../actions/rerenderAction";

export default function ExpenseCreateOptionManually({ children }) {
  // Handle modal
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(setExpense({}));
    dispatch(
      openModal({
        modalType: "static",
        modalTitle: "New Expense",
        modalSubmit: "SUBMIT_EXPENSE",
        key: "modalExpenseSubmit",
        needsDelete: false,
      })
    );
  };
  const modal = useSelector((store) => store.modal);

  const {
    showSuccessAlert,
    setShowSuccessAlert,
    showErrorAlert,
    setShowErrorAlert,
  } = useShowAlert();

  const { expenseCreateUpdate, expenseCreateUpdateResponse } =
    useExpenseCreateUpdate();

  const modalSubmit = () => {
    expenseCreateUpdate();
    dispatch(closeModal({}));
  };

  useEffect(() => {
    if (
      expenseCreateUpdateResponse &&
      expenseCreateUpdateResponse.status === 200
    ) {
      if (expenseCreateUpdateResponse.status === 200) {
        setShowSuccessAlert(true);
        dispatch(setRerender());
      } else {
        setShowErrorAlert(true);
      }
    }
  }, [expenseCreateUpdateResponse]);

  return (
    <>
      <div
        onClick={handleOpenModal}
        className="hover:text-primary-500 hover:cursor-pointer"
      >
        {children}

        {showErrorAlert && (
          <Alert
            message="Failed to create expense."
            type="error"
            duration={2000}
          />
        )}
        {showSuccessAlert && (
          <Alert
            message="Expense created successfully."
            type="success"
            duration={2000}
          />
        )}
      </div>
      {modal.isOpen && modal.key === "modalExpenseSubmit" && (
        <Modal modalSubmit={modalSubmit} type="expenseForm">
          <ExpenseForm />
        </Modal>
      )}
    </>
  );
}
