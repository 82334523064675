import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LineController,
} from "chart.js";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  LineController
);

const useShowChart = () => {
  const [chartData, setChartData] = useState({
    xAxisLabels: [],
    tableData: [],
  });

  const [chart, setChart] = useState({});

  const data = {
    labels: chartData.xAxisLabels,
    datasets: [
      {
        label: "total",
        data: chartData.tableData,
        backgroundColor: "#AFCAF3",
        borderColor: "#0F3061",
        tension: 0.4,
        pointRadius: 6,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        type: "category",
        labels: chartData.xAxisLabels,
        grid: {
          display: null,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    if (chart && chartData.tableData.length > 0) {
      const newChart = new ChartJS(chart, {
        type: "line",
        data: data,
        options: options,
      });
      return () => newChart.destroy();
    }
  }, [chart, chartData]);

  return {
    setChartData,
    setChart,
  };
};

export default useShowChart;
