// Protected.js

import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import OverlayLoader from "../elements/OverlayLoader";

export default function Protected({ element }) {
  const { user, ready } = useContext(UserContext);

  if (!localStorage.getItem("token"))
    return <Navigate to="/login" />;

  if (!ready || !user)
    return <OverlayLoader />;

  return <>{element}</>;
}
