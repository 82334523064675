import axios from "axios";
import { useState } from "react";

const useUserRegisterUuid = () => {
  // Variables
  const [userRegisterUuidResponse, setUserRegisterUuidResponse] =
    useState(null);

  // Patch user register by uuid
  const userRegisterUuid = async (uuid, data) => {
    await axios
      .patch(`user/register/${uuid}`, data)
      .then((response) => {
        setUserRegisterUuidResponse(response);
      })
      .catch((error) => {});
  };
  return { userRegisterUuid, userRegisterUuidResponse };
};

export default useUserRegisterUuid;
