import useFetchCompany from "../../actions/hooks/useFetchCompany";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import SelectField from "../layout/SelectField";
const UsersDropdown = ({ onChange, report, label, width }) => {
  const [users, setUsers] = useState([]);
  const companyData = useFetchCompany();
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (companyData && companyData.users) {
      setUsers(companyData.users);
    }
  }, [companyData]);
  return (
    <>
      <div>
        <label className="block uppercase tracking-wide text-xs font-bold mb-1">
          {label}
        </label>
        <SelectField onChange={(e) => onChange(e.target.value)}>
          {report && report.assignee && report.assignee.first_name ? (
            <option value={report.assignee.id}>
              {user.id === report.assignee.id
                ? `${user.first_name} ${user.last_name} (me)`
                : `${report.assignee.first_name} ${report.assignee.last_name} (${report.assignee.email})`}
            </option>
          ) : (
            <option value="">Select administrator</option>
          )}
          {users
            .filter(
              (userElement) =>
                userElement.roles.some(
                  (role) => role.name === "administrator"
                ) &&
                (!report ||
                  !report.assignee ||
                  userElement.email !== report.assignee.email)
            )
            .map((userElement) => (
              <option key={userElement.id} value={userElement.id}>
                {userElement.first_name} {userElement.last_name} (
                {userElement.email})
              </option>
            ))}
          {report && user.id !== report.assignee.id && (
            <option key={user.id} value={user.id}>
              {user.first_name} {user.last_name} (me)
            </option>
          )}
        </SelectField>
      </div>
    </>
  );
};

export default UsersDropdown;
