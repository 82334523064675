export default function Card({
  header,
  headerRight,
  children,
  hideSeparator = null,
}) {
  return (
    <>
      <div className="flex flex-col items-center">
        <div
          className={`w-full border rounded-md bg-white ${
            header ? "p-3" : "pb-3"
          }`}
        >
          {header && (
            <div
              className={
                hideSeparator === null
                  ? "w-full border-b border-gray-200 pb-3"
                  : ""
              }
            >
              <div className="flex justify-between items-center">
                <h3 className="text-lg">{header}</h3>
                {headerRight && <div>{headerRight}</div>}
              </div>
            </div>
          )}
          {children && <div className="pt-3">{children}</div>}
        </div>
      </div>
    </>
  );
}
