import axios from "axios";
import { useState } from "react";

const useFetchComments = () => {
  const [fetchCommentsResponse, setFetchCommentsResponse] = useState();

  const fetchComments = async (report_id) => {
    try {
      const response = await axios.get(`comments/${report_id}`);
      setFetchCommentsResponse(response);
    } catch (error) {}
  };

  return { fetchComments, fetchCommentsResponse };
};

export default useFetchComments;
