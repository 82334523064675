import axios from "axios";
import { useState, useRef } from "react";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useReportUpdate = () => {
  // Variables
  const [reportUpdateResponse, setReportUpdateResponse] = useState(null);
  const dispatch = useDispatch();
  const startTimeRef = useRef(null);

  // Function to update report in db
  const reportUpdate = async (id, data) => {
    dispatch(startLoader({}));
    startTimeRef.current = performance.now();
    let hasError = false;
    try {
      const response = await axios.patch(`report/update/${id}`, data);
      setReportUpdateResponse(response);
    } catch (error) {
      hasError = true;
    } finally {
      const endTime = performance.now();
      const elapsedTime = endTime - startTimeRef.current;

      if (elapsedTime < 500) {
        setTimeout(
          () => dispatch(stopLoader({})),
          hasError ? 4000 : 500 - elapsedTime
        );
      } else {
        dispatch(stopLoader({}));
      }
    }
  };

  return { reportUpdate, reportUpdateResponse };
};

export default useReportUpdate;
