import { Outlet } from "react-router-dom";
import Aside from "./Aside";
import Footer from "./Footer";
import { useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { triggerAside } from "../../actions/asideAction";
import { useState } from "react";
import OverlayLoader from "../elements/OverlayLoader";
export default function Layout() {
  const { user } = useContext(UserContext);
  const aside = useSelector((store) => store.aside);
  const overlayLoader = useSelector((store) => store.loader);
  const dispatch = useDispatch();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOnClick = () => {
    if (!aside.isOpen || window.innerWidth >= 640) return;
    dispatch(triggerAside());
  };

  return (
    <div>
      {overlayLoader.loading && <OverlayLoader />}
      <div className="flex flex-col min-h-screen" onClick={handleOnClick}>
        {user && (aside.isOpen || windowWidth >= 640) && <Aside />}

        <div
          className={`${
            aside.isOpen && windowWidth < 640 ? "opacity-50" : ""
          } ${!!user ? "sm:ml-64" : ""}`}
        >
          <div className="bg-gray-100 min-h-screen p-4 md:p-4">
            <Outlet />
          </div>
          <div className="bg-gray-100 py-4 px-4">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
