import { useEffect, useState } from "react";
import useExpenseCreate from "../useExpenseCreate";
import useExpenseUpdate from "../useExpenseUpdate";
import { useSelector } from "react-redux";

const useExpenseCreateUpdate = () => {
  const expense = useSelector((store) => store.expense);

  const { expenseCreate, expenseCreateResponse } = useExpenseCreate();
  const { expenseUpdate, expenseUpdateResponse } = useExpenseUpdate();

  const [expenseCreateUpdateResponse, setExpenseCreateUpdateResponse] =
    useState();

  useEffect(() => {
    if (expenseUpdateResponse && expenseUpdateResponse.status === 200) {
      setExpenseCreateUpdateResponse(expenseUpdateResponse);
    }
  }, [expenseUpdateResponse]);

  useEffect(() => {
    if (
      expenseCreateResponse &&
      expenseCreateResponse.status === 200 &&
      expenseCreateResponse.data.expense.id
    ) {
      expenseUpdate(expenseCreateResponse.data.expense.id, expense);
    }
  }, [expenseCreateResponse]);

  const expenseCreateUpdate = () => {
    expenseCreate();
  };

  return {
    expenseCreateUpdate,
    expenseCreateUpdateResponse,
  };
};

export default useExpenseCreateUpdate;
