import useFetchUserReports from "../../actions/hooks/useFetchUserReports";
import ReportItem from "./ReportItem";
import { useState, useEffect } from "react";
import ListItemWrapper from "../layout/ListItemWrapper";
import ListItemsWrapper from "../layout/ListItemsWrapper";
import OrderBy from "../expenses/OrderBy";
import ReportNotFound from "./ReportNotFound";
import LoadError from "../elements/LoadError";

export default function ReportsList({ selectedStates, startDate, endDate }) {
  const reportsResponse = useFetchUserReports();
  const [reports, setReports] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [loading, setLoading] = useState(true);

  const orderByOptions = [
    { property: "created_at", label: "date" },
    { property: "name", label: "Name" },
    { property: "total.amount", label: "amount" },
    { property: "status", label: "status" },
  ];

  useEffect(() => {
    if (reportsResponse) {
      setReports(reportsResponse.data.reports);
      setLoading(false);
    }
  }, [reportsResponse]);

  const getNestedValue = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
      obj
    );
  };

  const filteredReports = reports
    .filter(
      (report) =>
        selectedStates.includes(report.status) || selectedStates.length === 0
    )
    .filter((report) => {
      const reportDate = new Date(report.created_at)
        .toISOString()
        .split("T")[0];
      return (
        (!startDate || reportDate >= startDate) &&
        (!endDate || reportDate <= endDate)
      );
    })
    .sort((a, b) => {
      if (!orderBy || !orderBy.type) {
        // If orderBy is not set, maintain the existing order
        return 0;
      }

      const aValue = getNestedValue(a, orderBy.type);
      const bValue = getNestedValue(b, orderBy.type);

      if (!orderBy.asc) {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    })
    .map((report, index) => (
      <ListItemWrapper key={index}>
        <ReportItem report={report} />
      </ListItemWrapper>
    ));

  if (loading) {
    return <LoadError />;
  }

  return (
    <ListItemsWrapper>
      <OrderBy onChange={setOrderBy} orderByOptions={orderByOptions} />
      {filteredReports.length > 0 ? filteredReports : <ReportNotFound />}
    </ListItemsWrapper>
  );
}
