import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Avatar({ user, hiddenTitle, shortTitle }) {
  const initials = user.first_name.charAt(0) + user.last_name.charAt(0);
  const fullName = `${user.first_name} ${user.last_name}`;
  const isLongName = fullName.length > 20;

  return (
    <div className="flex flex-col items-center">
      <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full border border-gray-200">
        <span className="font-medium text-gray-600">
          {user.invitation_token ? (
            <>
              <FontAwesomeIcon icon={faEnvelope} />
            </>
          ) : (
            <>{initials}</>
          )}
        </span>
      </div>

      {user.invitation_token ? (
        <div className="text-lg text-danger">Invitation pending</div>
      ) : !hiddenTitle ? (
        isLongName ? (
          shortTitle ? (
            <div className="text-lg">
              {user.first_name.charAt(0)}. {user.last_name}
            </div>
          ) : (
            <>
              <div className="text-lg">{user.first_name}</div>
              <div className="text-lg">{user.last_name}</div>
            </>
          )
        ) : (
          <div className="text-lg">{fullName}</div>
        )
      ) : null}
    </div>
  );
}
