import { useState, useEffect } from "react";
import InputField from "../layout/InputField";
import ExpenseItem from "../expenses/ExpenseItem";
import useFetchExpenses from "../../actions/hooks/useFetchExpenses";
import { useDispatch, useSelector } from "react-redux";
import { setReport } from "../../actions/reportActions";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import UsersDropdown from "../user/UsersDropdown";

const ReportFormRange = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportName, setReportName] = useState(
    `Report ${new Date().toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })}`
  );
  const expensesArray = useFetchExpenses();
  const [filteredExpensesArray, setFilteredExpensesArray] = useState([]);
  const { user } = useContext(UserContext);
  const [toUser, setToUser] = useState();
  const report = useSelector((store) => store.report);

  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const filteredExpenses = expensesArray
        .filter((expense) => expense.state === "unreported")
        .filter((expense) => {
          const expenseDate = new Date(expense.date)
            .toISOString()
            .split("T")[0];
          return (
            (!startDate || expenseDate >= startDate) &&
            (!endDate || expenseDate <= endDate)
          );
        })
        .map((expense) => expense); // Only return the expense.id values
      const expenseIds = filteredExpenses.map((expense) => expense.id);
      setFilteredExpensesArray(filteredExpenses);

      dispatch(
        setReport({
          status: "open",
          name: reportName,
          from_id: user.id,
          to_id: toUser,
          id: null,
          startDate: startDate,
          endDate: endDate,
          expense_id: expenseIds,
        })
      );
    }
  }, [startDate, endDate, expensesArray, reportName, toUser]);

  return (
    <>
      <form className="flex flex-col gap-4">
        <div>
          <InputField
            name="reportName"
            label="Report Name"
            placeholder="New Report"
            required
            type="text"
            onChange={(e) => {
              setReportName(e.target.value);
            }}
          />
        </div>
        <div className="flex gap-4">
          <InputField
            name="dateFrom"
            label="From"
            required
            type="date"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
          <InputField
            name="dateUntil"
            label="Until"
            required
            type="date"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
          <UsersDropdown
            onChange={(selectedUserId) => setToUser(selectedUserId)}
            label="Select administrator to assign the report"
          />
        </div>
      </form>
      {startDate && endDate && filteredExpensesArray.length !== 0 && (
        <>
          <div className="flex flex-col gap-2">
            <div className="py-1">
              <span className="font-semibold">
                Reports from: {startDate} until: {endDate}
              </span>
            </div>

            <div className="h-[400px] overflow-y-auto">
              <ul className="flex flex-col gap-4">
                {filteredExpensesArray.map((expense, index) => (
                  <li key={index}>
                    <div
                      className={`px-4 py-4 bg-white shadow-sm border rounded-md  border-2`}
                    >
                      <ExpenseItem expense={expense} onCheckChange={() => {}} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReportFormRange;
