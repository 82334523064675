import Checkbox from "../elements/Checkbox";
import InputField from "../layout/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function UsersFilters({ filters, setFilters }) {
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Checkbox
            label="Show archived"
            checked={filters.show.includes("archived") ? 1 : 0}
            onChange={() =>
              setFilters({
                ...filters,
                show: filters.show.includes("archived")
                  ? filters.show.filter((item) => item !== "archived")
                  : [...filters.show, "archived"],
              })
            }
          />
          <Checkbox
            label="Show active"
            checked={filters.show.includes("active") ? 1 : 0}
            onChange={() =>
              setFilters({
                ...filters,
                show: filters.show.includes("active")
                  ? filters.show.filter((item) => item !== "active")
                  : [...filters.show, "active"],
              })
            }
          />
        </div>
        <div>
          <InputField
            placeholder="name@example.com"
            icon={<FontAwesomeIcon icon={faSearch} />}
            value={filters.regex}
            onChange={(e) =>
              setFilters({
                ...filters,
                regex: e.target.value,
              })
            }
          />
        </div>
      </div>
    </>
  );
}
