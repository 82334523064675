// states.js
const expenseStates = [
  "unreported",
  "open",
  "processing",
  "approved",
  "deleted",
  "reimbursed",
  "closed",
];

export default expenseStates;
