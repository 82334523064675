import React from "react";
import PropTypes from "prop-types";
import { faDollarSign, faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Price = ({ price }) => {
  if (!price || !price.amount || isNaN(parseFloat(price.amount))) {
    return null;
  }

  const formattedPrice = parseFloat(price.amount).toFixed(2);
  const [integerPart, decimalPart] = formattedPrice.split(".");

  const currencyIcon = price.currency === "EUR" ? faEuroSign : faDollarSign;

  return (
    <div className="items-start">
      <div className="flex items-center">
        <FontAwesomeIcon icon={currencyIcon} className="align-top" />
        <span className="font-semibold">{integerPart}</span>
        <span className="align-top ml-1 font-semibold">.{decimalPart}</span>
      </div>
    </div>
  );
};

Price.propTypes = {
  price: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
  }),
};

export default Price;
