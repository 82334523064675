const StatePill = ({ state, size }) => {
  const capitalizedState = state.charAt(0).toUpperCase() + state.slice(1);

  let color = "bg-" + state;

  switch (state) {
    case "unreported":
      color = "bg-unreported";
      break;
    case "open":
      color = "bg-open";
      break;
    case "processing":
      color = "bg-processing";
      break;
    case "reimbursed":
      color = "bg-reimbursed";
      break;
    case "closed":
      color = "bg-closed";
      break;
    case "approved":
      color = "bg-approved";
      break;
    case "deleted":
      color = "bg-deleted";
      break;
    default:
      color = "bg-primary";
      break;
  }

  let padding = "px-2 py-1";
  let text = "text-sm";
  let width = "w-24";
  switch (size) {
    case "sm":
      padding = "";
      text = "text-xs";
      width = "w-24";
      break;

    default:
      padding = "px-2 py-1";
      text = "text-sm";
      width = "w-28";
      break;
  }

  const classNames = `flex inline-block justify-center items-center ${color} text-white ${padding} rounded-full ${width}`;
  return (
    <div className={classNames}>
      <span className={text}>{capitalizedState}</span>
    </div>
  );
};

export default StatePill;
