import axios from "axios";
import { useState } from "react";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useUserResetPasswordRequest = () => {
  // Variables
  const [userResetPasswordRequestResponse, setUserResetPasswordRequestResponse] = useState(null);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);

  // Patch user register by uuid
  const userResetPasswordRequest = async (email) => {
    dispatch(startLoader({}));
    try {
      const response = await axios.post(`user/passwordResetRequest`, { email });
      setUserResetPasswordRequestResponse(response);
    } catch (error) {
      setUserResetPasswordRequestResponse(error.response.status);
    } finally {
      dispatch(stopLoader({}));
      setStatus((status) => !status);
    }
  };

  return { userResetPasswordRequest, userResetPasswordRequestResponse, status };
};

export default useUserResetPasswordRequest;
