import React from "react";

const Container = ({ children }) => {
  return (
    <div className="container mx-auto sm:px-0 xl:px-20">
      <div className="flex flex-col flex-items gap-6 p-3">{children}</div>
    </div>
  );
};

export default Container;
