import axios from "axios";
import { useState, useEffect } from "react";

const useFetchExpenses = () => {
  const [expenses, setExpenses] = useState([]); // Set a default value or an empty array

  useEffect(() => {
    const fetchExpenses = async () => {
      const expensesUrl = "user/expenses";
      try {
        const response = await axios.get(expensesUrl);
        setExpenses((expenses) => Object.values(response.data.expenses));
      } catch (error) {
        // Handle the error, e.g., setExpenses([]) or log the error
      }
    };

    fetchExpenses();
  }, []);

  return expenses;
};

export default useFetchExpenses;
