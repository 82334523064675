import ExpenseCreateOptionManually from "./ExpenseCreateOptionManually";
import ExpenseCreateOptionScan from "./ExpenseCreateOptionScan";
import DropDown from "../elements/DropDown";
import DropDownOptionWrapper from "../layout/DropDownOptionWrapper";

export default function ExpenseCreateOptions() {
  return (
    <>
      <DropDown label="New expense">
        <DropDownOptionWrapper>
          <ExpenseCreateOptionManually>
            <span>Create manually</span>
          </ExpenseCreateOptionManually>
        </DropDownOptionWrapper>
        <DropDownOptionWrapper>
          <ExpenseCreateOptionScan>
            <span>Scan</span>
          </ExpenseCreateOptionScan>
        </DropDownOptionWrapper>
      </DropDown>
    </>
  );
}
