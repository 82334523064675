import React, { useState, useEffect, useRef } from "react";
import Button from "../elements/Button";
import {
  format,
  startOfWeek,
  startOfMonth,
  addDays,
  subWeeks,
  subMonths,
} from "date-fns";
import DropDown from "../elements/DropDown";
import useShowChart from "../../actions/hooks/utility/useShowChart";

const ReportsGraph = ({ reports, onLoad }) => {
  const chartRef = useRef(null);
  const [range, setRange] = useState({
    start: startOfWeek(new Date()),
    end: null,
  });

  const [graphView, setGraphView] = useState("This Week");
  const filterReportsByRange = () => {
    return reports.filter((report) => {
      if (range.end) {
        return (
          new Date(report.created_at) >= range.start &&
          new Date(report.created_at) <= range.end
        );
      }
      return new Date(report.created_at) >= range.start;
    });
  };

  const { setChartData, setChart } = useShowChart();

  useEffect(() => {
    const filteredReports = filterReportsByRange();

    if (graphView === "Rolling 13 weeks") {
      // Initialize an array to hold the counts for each week
      const weekCounts = Array(13).fill(0);

      // Iterate through reports and increment the count for the corresponding week
      filteredReports.forEach((report) => {
        const reportDate = new Date(report.created_at);
        // Find the closest Monday
        const closestMonday = new Date(reportDate);
        closestMonday.setDate(
          reportDate.getDate() - ((reportDate.getDay() + 6) % 7)
        );

        // Calculate weeksAgo from the closest Monday
        const weeksAgo = Math.floor(
          (new Date() - closestMonday) / (7 * 24 * 60 * 60 * 1000)
        );
        weekCounts[weeksAgo]++;
      });

      // Reverse the weekCounts array to make the current week the last in the list
      setChartData({
        xAxisLabels: Array.from({ length: 13 }, (_, index) => {
          // Calculate the start and end date of the week
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - (index + 1) * 7);
          const endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + 6);

          // Format the dates to show the span of the week
          return `${startDate.getDate()}/${
            startDate.getMonth() + 1
          } - ${endDate.getDate()}/${endDate.getMonth() + 1}`;
        }).reverse(),
        tableData: weekCounts.reverse(),
      });
    } else {
      const dateCountsMap = new Map();
      filteredReports.forEach((report) => {
        const date = format(new Date(report.created_at), "yyyy-MM-dd");
        dateCountsMap.set(date, (dateCountsMap.get(date) || 0) + 1);
      });

      setChartData({
        xAxisLabels: Array.from(dateCountsMap.keys()).reverse(),
        tableData: Array.from(dateCountsMap.keys())
          .map((date) => dateCountsMap.get(date))
          .reverse(),
      });
    }

    setChart(chartRef.current);

    // return destroy
  }, [range, reports]);

  const buttons = [
    {
      label: "Rolling 13 weeks",
      range: {
        start: startOfWeek(subWeeks(new Date(), 13)),
        end: new Date(),
      },
    },
    {
      label: "Last month",
      range: {
        start: startOfMonth(subMonths(new Date(), 1)),
        end: addDays(startOfMonth(new Date()), -1),
      },
    },
    {
      label: "Last week",
      range: {
        start: startOfWeek(subWeeks(new Date(), 1)),
        end: addDays(startOfWeek(subWeeks(new Date(), 1)), 6),
      },
    },
    {
      label: "This week",
      range: { start: startOfWeek(new Date()), end: null },
    },
    {
      label: "This month",
      range: { start: startOfMonth(new Date()), end: null },
    },
  ];

  const dropdown = () => {
    return (
      <>
        <DropDown label={graphView} width="w-36">
          <div className="flex flex-col gap-1 items-center py-1">
            {buttons.map((button, index) => (
              <Button
                key={index}
                onClick={() => {
                  setRange(button.range);
                  setGraphView(button.label);
                }}
                width="w-40"
              >
                {button.label}
              </Button>
            ))}
          </div>
        </DropDown>
      </>
    );
  };
  useEffect(() => {
    onLoad(dropdown);
  }, [graphView]);
  return (
    <div className="flex">
      <canvas ref={chartRef} className="w-full min-h-64 max-h-64" />
    </div>
  );
};

export default ReportsGraph;
