import { useEffect, useState, useContext } from "react";
import InputField from "../components/layout/InputField";
import useUserResetPassword from "../actions/hooks/useUserResetPassword";
import Button from "../components/elements/Button";
import { Navigate, useParams } from "react-router-dom";
import Card from "../components/elements/Card";
import Container from "../components/layout/Container";
import Alert from "../components/elements/Alert";
import useShowAlert from "../actions/hooks/utility/useShowAlert";
import { UserContext } from "../context/UserContext";
export default function ResetPasswordPage() {
    const [password, setPassword] = useState("");
    const { ready, user } = useContext(UserContext);

    const {
        showSuccessAlert,
        setShowSuccessAlert,
        showErrorAlert,
        setShowErrorAlert,
    } = useShowAlert();

    const [redirect, setRedirect] = useState(false);

    const { userResetPassword, userResetPasswordResponse, status } = useUserResetPassword();
    const { uuid } = useParams();
    const handleFormSubmit = () => {
        userResetPassword(uuid, password);
    };

    useEffect(() => {
        if (userResetPasswordResponse) {
            if (userResetPasswordResponse.status === 200) {
                setShowSuccessAlert(true);
                setRedirect(true);
            } else {
                setShowErrorAlert(true);
            }
        }
    }, [userResetPasswordResponse, setShowSuccessAlert, setShowErrorAlert, status]);

    if (redirect) {
        return <Navigate to="/login" />;
    }

    if ((ready && user))
        return <Navigate to={"/"} />;

    return (
        <>
            <div className="flex items-center justify-around">
                <div className="mt-32 py-9">
                    <Card>
                        <Container>
                            <h1 className="text-4xl text-center font-bold pb-4">Reset Password</h1>
                            <div className="flex flex-col gap-2">

                                <InputField
                                    required
                                    type="password"
                                    label="Password"
                                    placeholder="Password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    value={password}
                                />

                                <Button label="Submit" onClick={handleFormSubmit} />
                            </div>
                        </Container>
                    </Card>
                </div>
            </div>
            {showErrorAlert && (
                <Alert
                    message="Failed to update password."
                    type="error"
                    duration={2000}
                />
            )}
            {showSuccessAlert && (
                <Alert
                    message="Password has been updated successfully."
                    type="success"
                    duration={2000}
                />
            )}
        </>
    );
}
