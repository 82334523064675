import MagnifyingGlass from "../elements/MagnifyingGlass";

const Receipt = ({ src, magnifyingGlass }) => {
  return (
    <div>
      <span className="flex items-center rounded-lg">
        {src ? (
          magnifyingGlass ? (
            <MagnifyingGlass src={process.env.REACT_APP_API_URL + "/" + src} />
          ) : (
            <img
              src={process.env.REACT_APP_API_URL + "/" + src}
              alt="image"
              className="rounded-md w-16 h-20"
            />
          )
        ) : (
          <img
            src="/images/receipt1024.png"
            alt="image"
            className="rounded-md w-full"
          />
        )}
      </span>
    </div>
  );
};

export default Receipt;
