import ReportAddExpenseList from "../reports/ReportAddExpenseList";
import InputField from "../layout/InputField";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import UsersDropdown from "../user/UsersDropdown";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import useReportAddExpense from "../../actions/hooks/useReportAddExpense";
import { closeModal } from "../../actions/modalActions";
import { setReport } from "../../actions/reportActions";

const ReportSelectExpensesForm = () => {
  const [reportName, setReportName] = useState(null);
  const [filteredExpensesArray, setFilteredExpensesArray] = useState([]);
  const { user } = useContext(UserContext);
  const [toUser, setToUser] = useState();
  const dispatch = useDispatch();
  const report = useSelector((store) => store.report);
  const [reportNewExpenses, setReportNewExpenses] = useState([]);
  const { reportAddExpense, reportAddExpenseResponse } = useReportAddExpense();

  useEffect(() => {
    if (reportNewExpenses && reportNewExpenses.length > 0) {
      dispatch(
        setReport({
          status: "open",
          name: reportName
            ? reportName
            : `Report ${new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
              })}`,
          from_id: user.id,
          to_id: toUser,
          id: null,
          startDate: null,
          endDate: null,
          expense_id: reportNewExpenses,
        })
      );
    }
  }, [reportNewExpenses, reportName, toUser]);

  return (
    <>
      <form className="flex flex-col gap-4 pb-10">
        <div>
          <InputField
            name="reportName"
            label="Report Name"
            placeholder="New Report"
            required
            type="text"
            onChange={(e) => {
              setReportName(e.target.value);
            }}
          />
        </div>
        <UsersDropdown
          onChange={(selectedUserId) => setToUser(selectedUserId)}
          label="Select administrator to assign the report"
        />
      </form>
      <ReportAddExpenseList
        reportNewExpenses={reportNewExpenses}
        setReportNewExpenses={setReportNewExpenses}
      />
    </>
  );
};

export default ReportSelectExpensesForm;
