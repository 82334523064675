import { useRef } from "react";

const Checkbox = ({ label, checked, onChange, ...params }) => {
  const checkboxRef = useRef();

  return (
    <div className="flex items-center space-x-2 hover:cursor-pointer">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        ref={checkboxRef}
        {...params}
      />

      <span onClick={() => checkboxRef.current.click()} htmlFor={checkboxRef}>
        {label}
      </span>
    </div>
  );
};

export default Checkbox;
