import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useFetchCompany = () => {
  const [company, setCompany] = useState(null);
  const rerender = useSelector((store) => store.rerender);
  const dispatch = useDispatch();
  const startTimeRef = useRef(null);

  useEffect(() => {
    const fetchCompany = async () => {
      dispatch(startLoader({}));
      startTimeRef.current = performance.now();
      let hasError = false;
      try {
        const response = await axios.get("company");
        setCompany(response.data.company);
      } catch (error) {
        hasError = true;
      } finally {
        const endTime = performance.now();
        const elapsedTime = endTime - startTimeRef.current;

        if (elapsedTime < 500) {
          setTimeout(
            () => dispatch(stopLoader({})),
            hasError ? 4000 : 500 - elapsedTime
          );
        } else {
          dispatch(stopLoader({}));
        }
      }
    };

    fetchCompany();
  }, [rerender]);

  return company;
};

export default useFetchCompany;
