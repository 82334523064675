import axios from "axios";
import { useState } from "react";

const useReportComment = (id) => {
  // Variables
  const [reportCommentResponse, setReportCommentResponse] = useState(null);

  // Function to update expense in db
  const reportComment = async (payload) => {
    await axios
      .post(`report/${id}/comment/`, payload)
      .then((response) => {
        setReportCommentResponse(response);
      })
      .catch((error) => {});
  };
  return { reportComment, reportCommentResponse };
};

export default useReportComment;
