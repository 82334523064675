import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Receipt from "./Receipt";
import MagnifyingGlass from "../elements/MagnifyingGlass";

const ReceiptUploadForm = ({ onChange }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);

  // Handle input for receipt image
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onChange(file);
      setImage(file);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    if (file) {
      onChange(file);
      setImage(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  if (image) {
    return (
      <div className="flex flex-col items-center border-gray-400">
        <div className="relative">
          <MagnifyingGlass src={URL.createObjectURL(image)} />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
            {/* Magnifying glass icon */}
            <svg
              className="w-8 h-8 text-white bg-gray-800 p-2 rounded-full"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M15 14l6-6-6-6M7 14l-6-6 6-6"></path>
            </svg>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="p-2 m-4 text-center rounded-lg border-gray-400 py-4"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        className="hidden"
        id="receiptFileInput"
        multiple={false}
      />
      <label htmlFor="receiptFileInput" className="cursor-pointer">
        <div className="flex flex-col items-center gap-2">
          <div className="w-48">
            <Receipt />
          </div>
          <span className="font-semibold text-primary-400 text-sm">
            Click or drag & drop to upload a receipt
          </span>
        </div>
      </label>
    </div>
  );
};

export default ReceiptUploadForm;
