import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AsideItem({ name, icon, to }) {
  return (
    <Link
      to={to}
      className="flex items-center p-2 py-6 text-white hover:bg-primary-700 group"
    >
      <div className="pl-2">
        <FontAwesomeIcon icon={icon} />
        <span className="ms-3">{name}</span>
      </div>
    </Link>
  );
}
