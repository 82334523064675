import React from "react";
import Checkbox from "../elements/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { setExpense } from "../../actions/expenseAction";
import { setCategory } from "../../actions/expenseCategoryAction";
import InputField from "../layout/InputField";
import SelectField from "../layout/SelectField";
import Receipt from "./Receipt";
import ReceiptWrapper from "./ReceiptWrapper";
import useFetchUserReports from "../../actions/hooks/useFetchUserReports";
import { useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import Card from "../elements/Card";
import useFetchExpenseCategories from "../../actions/hooks/useFetchExpenseCategories";

const ExpenseForm = () => {
  const dispatch = useDispatch();
  const exp = useSelector((store) => store.expense);
  const reportsResponse = useFetchUserReports();
  const [categories, setCategories] = useState([]);
  const categoriesResponse = useFetchExpenseCategories();
  const [reports, setReports] = useState([]);
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (reportsResponse) {
      setReports(reportsResponse.data.reports);
    }
  }, [reportsResponse]);

  useEffect(() => {
    if (categoriesResponse) {
      setCategories(categoriesResponse.data.categories);
    }
  }, [categoriesResponse]);

  return (
    <Card>
      <div className="flex flex-col sm:flex-row flex-col-reverse items-center justify-around gap-4 rounded-lg py-4 px-4">
        <div>
          <ol className="flex flex-col gap-4">
            <li>
              <InputField
                label="Merchant"
                required
                type="text"
                placeholder="Merchant Name"
                maxLength="255"
                autoComplete="off"
                value={
                  exp.merchant && exp.merchant.name ? exp.merchant.name : ""
                }
                onChange={(e) => {
                  dispatch(
                    setExpense({
                      ...exp,
                      merchant: {
                        ...exp.merchant,
                        name: e.target.value,
                      },
                    })
                  );
                }}
                disabled={exp.state !== "open" && exp.state !== "unreported"}
              />
            </li>
            <li>
              <div className="flex flex-col">
                <InputField
                  required
                  label="Date"
                  type="date"
                  value={exp.date && exp.date !== null ? exp.date : ""}
                  onChange={(e) => {
                    dispatch(
                      setExpense({
                        ...exp,
                        date: e.target.value,
                      })
                    );
                  }}
                  disabled={exp.state !== "open" && exp.state !== "unreported"}
                />
              </div>
            </li>
            <li>
              <div className="flex flex-row gap-3">
                <InputField
                  label="Total"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder="0.00"
                  maxLength="255"
                  required
                  autoComplete="off"
                  value={exp.price && exp.price.amount ? exp.price.amount : ""}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
                    dispatch(
                      setExpense({
                        ...exp,
                        price: {
                          ...exp.price,
                          amount: numericValue,
                        },
                      })
                    );
                  }}
                  disabled={exp.state !== "open" && exp.state !== "unreported"}
                />
                <SelectField
                  label="Currency"
                  value={
                    exp.price && exp.price.currency ? exp.price.currency : ""
                  }
                  onChange={(e) => {
                    dispatch(
                      setExpense({
                        ...exp,
                        price: {
                          ...exp.price,
                          currency: e.target.value,
                        },
                      })
                    );
                  }}
                  disabled={exp.state !== "open" && exp.state !== "unreported"}
                >
                  <option value="">Select Currency</option>
                  <option value="EUR">EUR</option>
                  <option value="USD">USD</option>
                  <option value="GBP">GBP</option>
                </SelectField>
              </div>
            </li>
            <li>
              <div className="flex flex-row">
                <SelectField
                  label="Payment method"
                  value={exp.payment_method ? exp.payment_method : ""}
                  onChange={(e) =>
                    dispatch(
                      setExpense({ ...exp, payment_method: e.target.value })
                    )
                  }
                  disabled={exp.state !== "open" && exp.state !== "unreported"}
                >
                  <option value="">Select Payment Method</option>
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                  <option value="Other">Other</option>
                </SelectField>
              </div>
            </li>
            <li>
              <div className="flex flex-row">
                <SelectField
                  label="Select Category"
                  value={exp.category_id ? exp.category_id : ""}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      dispatch(
                        setExpense({
                          ...exp,
                          category_id: e.target.value,
                        })
                      );
                    }
                  }}
                  disabled={exp.state !== "open" && exp.state !== "unreported"}
                >
                  <option value="">No category selected</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>{category.title}</option>
                  ))}
                </SelectField>
              </div>
            </li>
            <li>
              <InputField
                label="Description"
                type="text"
                maxLength="255"
                autoComplete="off"
                value={exp.description ? exp.description : ""}
                onChange={(e) =>
                  dispatch(setExpense({ ...exp, description: e.target.value }))
                }
                disabled={exp.state !== "open" && exp.state !== "unreported"}
              />
            </li>
            <li>
              <Checkbox
                label="Reimbursable"
                checked={exp.reimbursable ? 1 : 0}
                onChange={(e) =>
                  dispatch(
                    setExpense({
                      ...exp,
                      reimbursable: e.target.checked ? 1 : 0,
                    })
                  )
                }
                disabled={exp.state !== "open" && exp.state !== "unreported"}
              />
            </li>
            <li>
              {(exp.state === "open" || exp.state === "unreported") && (
                <div className="flex flex-row">
                  <SelectField
                    label="Select Report"
                    value={exp.report_id ? exp.report_id : "No report selected"}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        dispatch(
                          setExpense({
                            ...exp,
                            report_id: e.target.value,
                            state: "open",
                          })
                        );
                      } else {
                        dispatch(
                          setExpense({
                            ...exp,
                            report_id: e.target.value,
                            state: "unreported",
                          })
                        );
                      }
                    }}
                  >
                    <option value="">No report selected</option>
                    {reports
                      .filter((report) => report.from_id === user.id)
                      .filter((report) => report.status === "open")
                      .map((report) => (
                        <option key={report.id} value={report.id}>
                          {report.name}
                        </option>
                      ))}
                  </SelectField>
                </div>
              )}
            </li>
          </ol>
        </div>

        <ReceiptWrapper>
          {exp && exp.supporting_documents[0] ? (
            <Receipt
              src={exp.supporting_documents[0].file_path}
              magnifyingGlass
            />
          ) : (
            <Receipt />
          )}
        </ReceiptWrapper>
      </div>
    </Card>
  );
};

export default ExpenseForm;
