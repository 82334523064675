import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ExpenseItem from "./ExpenseItem";
import Modal from "../elements/Modal";
import ExpenseForm from "./ExpenseForm";
import useExpenseEditModal from "../../actions/hooks/utility/useExpenseEditModal";
import { setRerender } from "../../actions/rerenderAction";
import { useDispatch, useSelector } from "react-redux";
import LoadError from "../elements/LoadError";
import ListItemWrapper from "../layout/ListItemWrapper";
import ListItemsWrapper from "../layout/ListItemsWrapper";
import OrderBy from "./OrderBy";
import ExpenseNotFound from "./ExpenseNotFound";
import { startLoader, stopLoader } from "../../actions/loaderActions";

const ExpensesList = ({ selectedStates, startDate, endDate }) => {
  const [expensesArray, setExpensesArray] = useState([]);
  const rerender = useSelector((store) => store.rerender);
  const expense = useSelector((store) => store.expense);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState(null);
  const startTimeRef = useRef(null);

  const getNestedValue = (obj, path) => {
    const keys = path.split(".");
    return keys.reduce(
      (acc, key) => (acc && acc[key] !== "undefined" ? acc[key] : undefined),
      obj
    );
  };

  const orderByOptions = [
    { property: "date", label: "Date" },
    { property: "merchant.name", label: "Merchant name" },
    { property: "price.amount", label: "Amount" },
    { property: "state", label: "State" },
    { property: "description", label: "Description" },
  ];

  // Fetch expenses from the server
  useEffect(() => {
    const fetchData = async () => {
      startTimeRef.current = performance.now();
      let hasError = false;
      const expensesUrl = "user/expenses";
      dispatch(startLoader({}));
      try {
        const response = await axios.get(expensesUrl);
        setExpensesArray(Object.values(response.data.expenses));
        setLoading(false);
      } catch (error) {
        hasError = true;
      } finally {
        const endTime = performance.now();
        const elapsedTime = endTime - startTimeRef.current;

        if (elapsedTime < 500) {
          setTimeout(
            () => dispatch(stopLoader({})),
            hasError ? 4000 : 500 - elapsedTime
          );
        } else {
          dispatch(stopLoader({}));
        }
      }
    };

    fetchData();
  }, [rerender]);

  const { handleOpenExpenseModal, modalSubmit, modalDelete, modal } =
    useExpenseEditModal();

  // Filter and map expenses based on selected states and date range
  const filteredExpenses = expensesArray
    .filter(
      (expense) =>
        selectedStates.includes(expense.state) || selectedStates.length === 0
    )
    .filter((expense) => {
      const expenseDate = new Date(expense.date).toISOString().split("T")[0];
      return (
        (!startDate || expenseDate >= startDate) &&
        (!endDate || expenseDate <= endDate)
      );
    })
    .sort((a, b) => {
      if (!orderBy || !orderBy.type) {
        // If orderBy is not set, maintain the existing order
        return 0;
      }

      const aValue = getNestedValue(a, orderBy.type);
      const bValue = getNestedValue(b, orderBy.type);

      if (!orderBy.asc) {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    })
    .map((expense, index) => (
      <ListItemWrapper key={index}>
        <ExpenseItem
          expense={expense}
          onCheckChange={() => dispatch(setRerender())}
          onClick={() => handleOpenExpenseModal(expense)}
        />
      </ListItemWrapper>
    ));

  if (loading) {
    return <LoadError />;
  }

  return (
    <>
      <ListItemsWrapper>
        <OrderBy onChange={setOrderBy} orderByOptions={orderByOptions} />
        {filteredExpenses.length > 0 ? filteredExpenses : <ExpenseNotFound />}
      </ListItemsWrapper>
      {modal.isOpen && modal.key === "editExpense" && (
        <Modal
          modalSubmit={modalSubmit}
          modalDelete={modalDelete}
          hiddenFooter={
            expense.state !== "open" && expense.state !== "unreported"
          }
          type="expenseForm"
        >
          <ExpenseForm />
        </Modal>
      )}
    </>
  );
};

export default ExpensesList;
