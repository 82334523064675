//expenseReducer.js;

const initialState = {
  receiptImage: null,
};

const receiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RECEIPT_IMAGE":
      return {
        receiptImage: action.payload,
      };
    case "EMPTY_RECEIPT_IMAGE":
      return {
        receiptImage: null,
      };
    default:
      return state;
  }
};

export default receiptReducer;
