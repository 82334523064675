import { useEffect, useState } from "react";
import useFetchArticlesByCategory from "../actions/hooks/useFetchArticlesByCategory";
import Container from "../components/layout/Container";
import Card from "../components/elements/Card";
import Article from "../components/articles/Article";
const HelpPage = () => {
  const articleResponse = useFetchArticlesByCategory("help");
  const [articlelist, setArticlelist] = useState([]);

  useEffect(() => {
    if (articleResponse) {
      setArticlelist(articleResponse);
    }
  }, [articleResponse]);

  const articles = articlelist.map((article) => {
    return (
      <div key={article.id}>
        <Article article={article} />
      </div>
    );
  });

  return (
    <>
      <Container>
        <Card>
          <div className=" px-4 text-lg text-center">
            <p className="font-semibold">Welcome to our help page!</p>
            <p>
              Here you can find a detailed guide regarding every aspect of the
              platform.
            </p>
          </div>
        </Card>
        {articles}
      </Container>
    </>
  );
};

export default HelpPage;
