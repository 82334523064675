export default function Button({ label, children, onClick, width, height }) {
  const widthClass = width ? `${width}` : "";
  const heightClass = height ? `${height}` : "";
  return (
    <button onClick={onClick}>
      <div
        className={`bg-primary text-white font-semibold cursor-pointer hover:bg-secondary py-2 px-4 flex flex-col items-center rounded-md  ${widthClass} ${heightClass}`}
      >
        <div className="flex flex-row gap-2 items-center">
          {children}
          <span className="text-center ">{label}</span>
        </div>
      </div>
    </button>
  );
}
