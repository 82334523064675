import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useFetchReport = (id) => {
  const [reportResponse, setReportResponse] = useState(null);
  const rerender = useSelector((store) => store.rerender);
  const startTimeRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchReport = async () => {
      dispatch(startLoader({}));
      startTimeRef.current = performance.now();
      let hasError = false;
      try {
        const response = await axios.get(`report/${id}`);
        setReportResponse(response);
      } catch (error) {
        setReportResponse(error.response.status);
        hasError = true;
      } finally {
        const endTime = performance.now();
        const elapsedTime = endTime - startTimeRef.current;
        if (elapsedTime < 500) {
          setTimeout(
            () => dispatch(stopLoader({})),
            hasError ? 4000 : 500 - elapsedTime
          );
        } else {
          dispatch(stopLoader({}));
        }
      }
    };

    fetchReport();
  }, [id, rerender]);

  return reportResponse;
};

export default useFetchReport;
