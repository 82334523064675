import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../PageTitle";
import ExpensesList from "../../components/expenses/ExpensesList";
import ExpensesFilters from "../../components/expenses/ExpensesFilters";
import ExpenseCreateOptions from "../../components/expenses/ExpenseCreateOptions";
import Container from "../../components/layout/Container";
import ToggleFilters from "../../components/expenses/ToggleFilters";

export default function ExpensesPage() {
  // Variables
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [filters, setFilters] = useState({});

  const onChangeFilters = (filters) => {
    setFilters(filters);
  };

  return (
    <div>
      <div>
        <PageTitle title="Expenses" />
      </div>
      <Container>
        <div className="flex items-center justify-between">
          <ToggleFilters onChange={setFiltersIsVisible} />
          <ExpenseCreateOptions />
        </div>
        <div className={`${filtersIsVisible ? "block" : "hidden"} z-10`}>
          <ExpensesFilters onChange={onChangeFilters} />
        </div>

        <div className="border-t border-gray-300 pb-2"></div>
        <ExpensesList
          selectedStates={filters.selectedStates}
          startDate={filters.startDate}
          endDate={filters.endDate}
        />
      </Container>
    </div>
  );
}
