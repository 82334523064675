import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../layout/Container";
import Card from "./Card";
import { useEffect, useState } from "react";

export default function LoadError() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const LoadingWrapper = ({ children }) => {
    return (
      <Container>
        <Card>
          <div className="flex flex-col items-center">
            <div className="flex flex-row gap-2 items-center justify-center">
              {children}
            </div>
          </div>
        </Card>
      </Container>
    );
  };

  if (!loading) {
    return (
      <>
        <LoadingWrapper>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-danger text-4xl"
          />
          <span>Something went wrong</span>
        </LoadingWrapper>
      </>
    );
  }

  return <></>;
}
