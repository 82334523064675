import Price from "../expenses/Price";
import Card from "../../components/elements/Card";
import ReportName from "./ReportName";
import { Link } from "react-router-dom";
import Button from "../elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import useReportUpdate from "../../actions/hooks/useReportUpdate";
import { setRerender } from "../../actions/rerenderAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import useExpenseUpdate from "../../actions/hooks/useExpenseUpdate";
import Avatar from "../user/Avatar";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import StatePill from "../expenses/StatePill";
import FormattedDate from "../elements/FormattedDate";
export default function ReportItem({ report }) {
  const dispatch = useDispatch();
  const firstExpense = report.expenses[0];
  const currency = firstExpense.price.currency ? firstExpense.price.currency : null;
  const { reportUpdate, reportUpdateResponse } = useReportUpdate();
  const { expenseUpdate } = useExpenseUpdate();
  const { user } = useContext(UserContext);
  const totalCost = report.expenses.reduce((total, expense) => {
    return total + parseFloat(expense.price.amount);
  }, 0);

  const price = {
    amount: totalCost,
    currency: currency,
  };

  // Re-set expenses state to unreported after succesfull report deletion
  useEffect(() => {
    if (reportUpdateResponse && reportUpdateResponse.status == 200) {
      report.expenses.map((expense) => {
        const updatedExpense = { ...expense, state: "unreported" };
        expenseUpdate(expense.id, updatedExpense);
      });
    }
  }, [report]);
  // Delete report
  const handleDelete = () => {
    reportUpdate(report.id, { ...report, status: "deleted" });
    dispatch(setRerender());
  };
  return (
    <>
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <div className="flex flex-col lg:flex-row items-center lg:justify-between lg:space-x-4 w-full">
          <div className="flex items-center space-x-4">
            <FormattedDate date={report.created_at} showIcon={true} />
            <div className="ml-4" title={report.name}>
              <span className="text-sm font-semibold">
                {report.name.length > 15
                  ? `${report.name.slice(0, 15)}...`
                  : report.name}
              </span>
              <div className="mt-2">
                <StatePill state={report.status} size="sm" />
              </div>
              <Price price={price} />
            </div>
          </div>

          <div className="flex lg:flex-row flex-col lg:space-x-4">
            <div className="flex lg:flex-row flex-col lg:space-x-4">
              <div
                className="flex flex-col items-center justify-center"
                title={report.creator.email}
              >
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-base">
                  Created by:
                </label>
                <Avatar
                  user={report.creator}
                  hiddenTitle={false}
                  shortTitle={
                    report.creator.first_name.length +
                      report.creator.last_name.length >
                    19
                  }
                />
              </div>

              <div
                className="flex flex-col items-center justify-center"
                title={report.assignee.email}
              >
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 text-base">
                  Assigned to:
                </label>
                <Avatar
                  user={report.assignee}
                  hiddenTitle={false}
                  shortTitle={
                    report.assignee.first_name.length +
                      report.assignee.last_name.length >
                    19
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2 mt-4 lg:mt-0">
            {report.creator.id === user.id && report.status === "open" ? (
              <>
                <Link to={`/report/${report.id}`}>
                  <Button label="Edit" />
                </Link>
                <Button
                  label="Delete"
                  children={<FontAwesomeIcon icon={faTrash} />}
                  onClick={handleDelete}
                />
              </>
            ) : (
              <Link to={`/report/${report.id}`}>
                <Button label="View" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
