import ReportCreateOptionDateRange from "./ReportCreateOptionDateRange";
import ReportCreateOptionSelectExpenses from "./ReportCreateOptionSelectExpenses";
import DropDown from "../elements/DropDown";
import DropDownOptionWrapper from "../layout/DropDownOptionWrapper";

const ReportCreateOptions = () => {
  return (
    <DropDown label="New report">
      <DropDownOptionWrapper>
        <ReportCreateOptionDateRange>
          <span>Select dates</span>
        </ReportCreateOptionDateRange>
      </DropDownOptionWrapper>
      <DropDownOptionWrapper>
        <ReportCreateOptionSelectExpenses>
          <span>Select expenses</span>
        </ReportCreateOptionSelectExpenses>
      </DropDownOptionWrapper>
    </DropDown>
  );
};

export default ReportCreateOptions;
