export const openModal = (payload) => ({
  type: "OPEN_MODAL",
  payload,
});

export const closeModal = () => ({
  type: "CLOSE_MODAL",
});

export const submitModal = (data, callback) => ({
  type: "SUBMIT_MODAL",
  payload: { data, callback },
});

export const submitExpense = (data) => ({
  type: "SUBMIT_EXPENSE",
  payload: { data },
});

export const submitReport = (data) => ({
  type: "SUBMIT_REPORT",
  payload: { data },
});
