import axios from "axios";
import { useState } from "react";

const useExpenseCreate = () => {
  // Variables
  const [expenseCreateResponse, setExpenseCreateResponse] = useState(null);

  // Function to update expense in db
  const expenseCreate = async () => {
    await axios
      .post("expense/create")
      .then((response) => {
        setExpenseCreateResponse(response);
      })
      .catch((error) => {});
  };
  return { expenseCreate, expenseCreateResponse };
};

export default useExpenseCreate;
