import React, { useState, useEffect, useRef } from "react";

const Article = ({ article }) => {
  const [showBody, setShowBody] = useState(false);
  const iframeRef = useRef(null);

  const toggleBody = () => {
    setShowBody((showBody) => !showBody);
  };

  useEffect(() => {
    const handleLoad = () => {
      if (iframeRef.current) {
        const iframeDocument = iframeRef.current.contentDocument;
        if (iframeDocument) {
          const iframeHeight = iframeDocument.documentElement.scrollHeight;
          iframeRef.current.style.height = `${iframeHeight}px`;
        }
      }
    };

    if (showBody && iframeRef.current) {
      iframeRef.current.addEventListener("load", handleLoad);

      return () => {
        if (iframeRef.current) {
          iframeRef.current.removeEventListener("load", handleLoad);
        }
      };
    }
  }, [showBody, article.body]);

  return (
    <div className="w-full mt-4">
      <div
        className="bg-white rounded-lg p-4 cursor-pointer"
        onClick={toggleBody}
      >
        <h2 className="block uppercase tracking-wide font-bold">
          {article.title}
        </h2>
      </div>
      {showBody && (
        <div className="mt-2 bg-white rounded-lg shadow-lg p-4">
          <iframe
            title="Article Content"
            srcDoc={article.body}
            style={{ width: "100%", border: "none" }}
            ref={iframeRef}
          />
        </div>
      )}
    </div>
  );
};

export default Article;
