import StatePill from "../expenses/StatePill";
import Table from "../elements/Table";
import FormattedDate from "../elements/FormattedDate";
import Price from "../expenses/Price";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const ReportsListShort = ({ reports }) => {
  const { user } = useContext(UserContext);
  const isAdmin = user.user_roles.includes("administrator");
  const headers = isAdmin
    ? ["", "Date", "Name", "Status", "Total", "From"]
    : ["", "Date", "Name", "Status", "Total", ""];

  let selectedStates = [];
  if (isAdmin) {
    selectedStates = ["processing"];
  } else {
    selectedStates = ["processing", "open"];
  }

  const filteredReports = reports.filter(
    (report) =>
      selectedStates.includes(report.status) && report.from_id !== report.to_id
  );

  const body = filteredReports.map((report) => [
    <Link to={`/report/${report.id}`} className="p-2 text-xl">
      <FontAwesomeIcon icon={faCaretRight} />
    </Link>,
    <div className="w-20 ">
      <FormattedDate date={report.created_at} />
    </div>,
    <div className="text-gray-700 text-base">{report.name}</div>,
    <StatePill state={report.status} size={"sm"} />,
    <Price price={report.total} />,
    isAdmin && (
      <span className="text-xs font-bold" title={report.creator.email}>
        {report.creator.first_name} {report.creator.last_name}
      </span>
    ),
  ]);

  return (
    <div className="max-h-64 min-h-64 overflow-auto">
      <Table headers={headers} body={body} />
    </div>
  );
};

export default ReportsListShort;
