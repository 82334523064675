export default function InputField({
  label,
  value,
  required,
  icon,
  ...params
}) {
  // Check if input field is valid
  const isValid = required && value === "";

  // Initialize classNames for input field
  const classNames = `appearance-none block w-full focus:outline-none border rounded py-2 px-4 ${
    isValid ? "border-red-500" : ""
  }`;

  return (
    <div>
      {label && (
        <label className="block uppercase tracking-wide text-xs font-bold mb-1">
          {label}
        </label>
      )}

      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 end-0 flex items-center pr-3 pointer-events-none text-sm">
            {icon}
          </div>
        )}
        <input
          className={`${classNames} ${icon ? "pr-8" : ""}`}
          value={value}
          {...params}
        />
      </div>
    </div>
  );
}
