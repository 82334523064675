import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAsc } from "@fortawesome/free-solid-svg-icons";
import { faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
export default function OrderBy({ onChange, orderByOptions }) {
  // Order by state
  const [orderBy, setOrderBy] = useState({
    type: null,
    asc: true,
  });

  useEffect(() => {
    onChange(orderBy);
  }, [orderBy]);

  // Handle order by change
  const handleOrderBy = (type) => {
    setOrderBy({ type, asc: !orderBy.asc });
  };

  // Render order by icon
  const OrderByIcon = ({ orderBy, type }) => {
    if (orderBy.type !== type) {
      return <FontAwesomeIcon icon={faSort} />;
    }

    return (
      <>
        {orderBy.asc ? (
          <FontAwesomeIcon icon={faSortAsc} />
        ) : (
          <FontAwesomeIcon icon={faSortDesc} />
        )}
      </>
    );
  };

  // Render order by item
  const OrderByItem = ({ property, label }) => {
    return (
      <span
        className={`uppercase text-xs font-semibold text-gray-600 flex items-center gap-1 hover:cursor-pointer ${
          property === orderBy.type ? "text-primary-600" : null
        }`}
        onClick={() => handleOrderBy(property)}
      >
        <OrderByIcon orderBy={orderBy} type={property} />
        <span>{label}</span>
      </span>
    );
  };

  // Order by component
  return (
    <div className="flex flex-row gap-6">
      {orderByOptions.map((item, index) => {
        return (
          <OrderByItem
            key={index}
            property={item.property}
            label={item.label}
          />
        );
      })}
    </div>
  );
}
