export default function TabItem({ label, to, active, onClick }) {
  return (
    <li className="me-2 cursor-pointer" onClick={onClick}>
      <div
        aria-current="page"
        className={`inline-block p-4 bg-gray-100 rounded-t-lg ${
          active
            ? "text-primary-700 active"
            : "hover:text-gray-600 hover:bg-gray-50"
        }`}
      >
        {label}
      </div>
    </li>
  );
}
