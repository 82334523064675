import { useState, useRef } from "react";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";
import axios from "axios";

const useReportRemoveExpense = (id) => {
  const [reportRemoveExpenseResponse, setReportRemoveExpenseResponse] =
    useState(null);
  const dispatch = useDispatch();
  const startTimeRef = useRef(null);
  // Function to create report in db
  const reportRemoveExpense = async (payload) => {
    dispatch(startLoader({}));
    startTimeRef.current = performance.now();
    let hasError = false;
    try {
      const response = await axios.patch(
        `report/remove/expense/${id}`,
        payload
      );
      setReportRemoveExpenseResponse(response);
    } catch (error) {
      hasError = true;
    } finally {
      const endTime = performance.now();
      const elapsedTime = endTime - startTimeRef.current;

      if (elapsedTime < 300) {
        setTimeout(
          () => dispatch(stopLoader({})),
          hasError ? 4000 : 500 - elapsedTime
        );
      } else {
        dispatch(stopLoader({}));
      }
    }
  };

  return { reportRemoveExpense, reportRemoveExpenseResponse };
};

export default useReportRemoveExpense;
