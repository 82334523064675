import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { Navigate } from "react-router-dom";
import PageTitle from "../PageTitle";
import Card from "../../components/elements/Card";
import UserForm from "../../components/user/UserForm";
import Container from "../../components/layout/Container";
import OverlayLoader from "../../components/elements/OverlayLoader";
export default function AccountPage() {
  const { ready, user, logout } = useContext(UserContext);

  if (!ready) {
    return <OverlayLoader />;
  }

  if (ready && !user) {
    return <Navigate to={"/login"} />;
  }

  const signOut = () => {
    logout();
    window.location.href = "/login";
  };

  return (
    <div>
      <div>
        <PageTitle title="Account" />
      </div>

      <Container>
        <Card header="My account">
          <UserForm />
        </Card>
        <button className="underline" type="button" onClick={signOut}>
          Sign out
        </button>
      </Container>
    </div>
  );
}
