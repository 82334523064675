import axios from "axios";
import { useState } from "react";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useUserResetPassword = () => {

    // Variables
    const [userResetPasswordResponse, setUserResetPasswordResponse] = useState(null);
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false);

    // Patch user password by uuid
    const userResetPassword = async (uuid, password) => {
        dispatch(startLoader({}));
        if (!password) {
            setUserResetPasswordResponse("Invalid password");
            dispatch(stopLoader({}));
            setStatus((status) => !status);
            return { userResetPassword, userResetPasswordResponse, status };
        }
        try {
            const response = await axios.post(`user/passwordResetRequest/${uuid}`, { password });
            setUserResetPasswordResponse(response);
        } catch (error) {
            setUserResetPasswordResponse(error.response.status);
        } finally {
            dispatch(stopLoader({}));
            setStatus((status) => !status);
        }
    };

    return { userResetPassword, userResetPasswordResponse, status };
};

export default useUserResetPassword;
