import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useFetchArticlesByCategory = (category) => {
  const [articlesResponse, setArticlesResponse] = useState(null);
  const startTimeRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchArticlesByCategory = async () => {
      startTimeRef.current = performance.now();
      let hasError = false;
      dispatch(startLoader({}));
      try {
        const response = await axios.get(`articles/${category}`);
        setArticlesResponse(response.data.articles);
      } catch (error) {
        setArticlesResponse(error.response.status);
        hasError = true;
      } finally {
        const endTime = performance.now();
        const elapsedTime = endTime - startTimeRef.current;
        if (elapsedTime < 500) {
          setTimeout(
            () => dispatch(stopLoader({})),
            hasError ? 4000 : 500 - elapsedTime
          );
        } else {
          dispatch(stopLoader({}));
        }
      }
    };
    fetchArticlesByCategory(category);
  }, [category]);
  return articlesResponse;
};

export default useFetchArticlesByCategory;
