import axios from "axios";
import { useState } from "react";

const useExpenseUpdate = () => {
  // Variables
  const [expenseUpdateResponse, setExpenseUpdateResponse] = useState(null);
  // Function to update expense in db
  const expenseUpdate = async (id, data) => {
    await axios
      .patch(`expense/update/${id}`, data)
      .then((response) => {
        setExpenseUpdateResponse(response);
      })
      .catch((error) => {});
  };
  return { expenseUpdate, expenseUpdateResponse };
};

export default useExpenseUpdate;
