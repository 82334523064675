import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../actions/modalActions";
import { useRef } from "react";

const Modal = ({ modalSubmit, modalDelete, children, hiddenFooter, type = null }) => {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const modal = useSelector((store) => store.modal);
  const merchant = useSelector((store) => store.expense.merchant);
  const date = useSelector((store) => store.expense.date);
  const price = useSelector((store) => store.expense.price);
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmitModal = async () => {
    modalSubmit();
  };

  if (!modal.isOpen) {
    return null;
  }

  return (
    <div
      ref={modalRef}
      className="sm:flex sm:items-center fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 md:justify-center"
    >
      <div className="relative bg-white rounded-lg shadow-lg w-full md:max-w-4xl p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-xl font-semibold text-gray-900">
            {modal.modalTitle}
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
            onClick={handleCloseModal}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>

        {children}

        {!hiddenFooter && (
          <div className="flex items-center justify-end mt-4">
            <button
              type="button"
              disabled={type === "expenseForm" && (!merchant || !merchant.name || !date || !price || !price.amount)}
              className={`text-white bg-approved focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center ${(type === "expenseForm" && (!merchant || !merchant.name || !date || !price || !price.amount))
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-approvedFocus focus:ring-primary-300'
                }`}

              onClick={handleSubmitModal}
            >
              Save
            </button>
            {modal.needsDelete && (
              <button
                type="button"
                className="ms-3 text-white bg-deleted hover:bg-deletedFocus focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10"
                onClick={modalDelete}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="ms-3 text-gray-500 bg-white hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
