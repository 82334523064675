import axios from "axios";
import { useState } from "react";

const useSupportingDocumentAnalyze = () => {
  // Variables
  const [
    supportingDocumentAnalyzeResponse,
    setSupportingDocumentAnalyzeResponse,
  ] = useState(null);

  // Analyze receipt to get the information
  const supportingDocumentAnalyze = async (receipt_id) => {
    await axios
      .post("supporting-document/analyze/" + receipt_id)
      .then((response) => {
        setSupportingDocumentAnalyzeResponse(response);
      })
      .catch((error) => {});
  };

  return { supportingDocumentAnalyze, supportingDocumentAnalyzeResponse };
};

export default useSupportingDocumentAnalyze;
