import axios from "axios";
import { useState } from "react";

const useSupportingDocumentDelete = () => {
  // Variables
  const [
    supportingDocumentDeleteResponse,
    setSupportingDocumentDeleteResponse,
  ] = useState(null);

  // Function to delete supporting document
  const supportingDocumentDelete = async (id) => {
    await axios
      .delete(`supporting-document/delete/${id}`)
      .then((response) => {
        setSupportingDocumentDeleteResponse(response);
      })
      .catch((error) => {});
  };
  return { supportingDocumentDelete, supportingDocumentDeleteResponse };
};

export default useSupportingDocumentDelete;
