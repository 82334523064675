import axios from "axios";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import LogoBlue from "../../components/elements/LogoBlue";
import InputField from "../../components/layout/InputField";
import { UserContext } from "../../context/UserContext";
import { useContext } from "react";

export default function RegisterPage() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    company: {
      vat: "",
      legal_name: "",
      profession: "",
      doy: "",
      address: "",
      phone: "",
    },
  });

  const [validationSuccess, setValidationSuccess] = useState(false);
  const { user, ready } = useContext(UserContext);

  function registerUser(ev) {
    ev.preventDefault();
    if (!validationSuccess) {
      axios
        .post("/user/validate", formData)
        .then((response) => {
          setValidationSuccess(true);
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            const errorMessages = Object.values(error.response.data.message)
              .flat()
              .join("\n");
            alert("Registration failed:\n" + errorMessages);
          } else {
            alert("Registration failed, please contact us");
          }
        });
    } else {
      axios
        .post("/user/register", formData)
        .then((response) => {
        })
        .catch((error) => {
          if (error.response && error.response.data.errors) {
            const errorMessages = Object.values(error.response.data.errors)
              .flat()
              .join("\n");
            alert("Registration failed:\n" + errorMessages);
          } else {
            alert("Registration failed, please contact us");
          }
        });
    }
  }

  if (ready && user)
    return <Navigate to={"/"} />;

  return (
    <div className="grow flex items-center justify-around">
      <div className="mb-64">
        <div className="pt-16 pb-4 flex justify-center">
          <LogoBlue width="72" />
        </div>
        <h1 className="uppercase text-sm text-center font-bold">Register</h1>
        {!validationSuccess ? (
          <form
            className="max-w-lg mx-auto flex flex-col gap-4 pt-4"
            onSubmit={registerUser}
          >
            <div className="flex gap-10">
              <InputField
                label="First name"
                type="text"
                required
                placeholder="First name"
                value={formData.first_name}
                onChange={(ev) =>
                  setFormData({
                    ...formData,
                    first_name: ev.target.value,
                  })
                }
              />
              <InputField
                label="Last name"
                required
                type="text"
                placeholder="Last name"
                value={formData.last_name}
                onChange={(ev) =>
                  setFormData({
                    ...formData,
                    last_name: ev.target.value,
                  })
                }
              />
            </div>

            <InputField
              label="Email"
              required
              type="email"
              placeholder="company@email.com"
              value={formData.email}
              onChange={(ev) =>
                setFormData({
                  ...formData,
                  email: ev.target.value,
                })
              }
            />
            <InputField
              label="Password"
              required
              type="password"
              placeholder="password"
              value={formData.password}
              onChange={(ev) =>
                setFormData({
                  ...formData,
                  password: ev.target.value,
                })
              }
            />
            <button className="primary" type="submit">
              Next
            </button>
          </form>
        ) : (
          <div>
            <h2 className="text-2xl mb-4 text-center">Company details</h2>
            <form
              className="flex flex-col gap-4 max-w-lg mx-auto"
              onSubmit={registerUser}
            >
              <div className="flex gap-4">
                <InputField
                  label="Company name"
                  required
                  type="text"
                  placeholder="Company name"
                  value={formData.company.legal_name}
                  onChange={(ev) =>
                    setFormData({
                      ...formData,
                      company: {
                        ...formData.company,
                        legal_name: ev.target.value,
                      },
                    })
                  }
                />
                <InputField
                  label="Vat"
                  required
                  type="text"
                  placeholder="VAT"
                  value={formData.company.vat}
                  onChange={(ev) =>
                    setFormData({
                      ...formData,
                      company: {
                        ...formData.company,
                        vat: ev.target.value,
                      },
                    })
                  }
                />
              </div>
              <InputField
                required
                label="Address"
                type="text"
                placeholder="Address"
                value={formData.company.address}
                onChange={(ev) =>
                  setFormData({
                    ...formData,
                    company: {
                      ...formData.company,
                      address: ev.target.value,
                    },
                  })
                }
              />
              <div className="flex gap-4">
                <InputField
                  required
                  label="Phone"
                  type="text"
                  placeholder="Phone"
                  value={formData.company.phone}
                  onChange={(ev) =>
                    setFormData({
                      ...formData,
                      company: {
                        ...formData.company,
                        phone: ev.target.value,
                      },
                    })
                  }
                />
                <InputField
                  required
                  label="Profession"
                  type="text"
                  placeholder="Profession"
                  value={formData.company.profession}
                  onChange={(ev) =>
                    setFormData({
                      ...formData,
                      company: {
                        ...formData.company,
                        profession: ev.target.value,
                      },
                    })
                  }
                />
              </div>
              <InputField
                label="Tax office"
                required
                type="text"
                placeholder="Tax office"
                value={formData.company.doy}
                onChange={(ev) =>
                  setFormData({
                    ...formData,
                    company: {
                      ...formData.company,
                      doy: ev.target.value,
                    },
                  })
                }
              />
              <button className="primary" type="submit">
                Register
              </button>
            </form>
          </div>
        )}

        <div className="flex gap-1 text-gray-600 py-2">
          Already have an account?
          <Link className="underline" to={"/login"}>
            Login
          </Link>
        </div>
      </div>
    </div>
  );
}
