import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useReportCreate = () => {
  const [reportCreateResponse, setReportCreateResponse] = useState(null);
  const report = useSelector((store) => store.report);
  // Function to create report in db
  const reportCreate = async () => {
    await axios
      .post(`report/create`, report)
      .then((response) => {
        setReportCreateResponse(response);
      })
      .catch((error) => {});
  };

  return { reportCreate, reportCreateResponse };
};

export default useReportCreate;
