import PageTitle from "./PageTitle";
import Container from "../components/layout/Container";
import Card from "../components/elements/Card";
import ReportsOverviewShort from "../components/reports/ReportsOverviewShort";
import ReportsGraph from "../components/reports/ReportsGraph";
import useFetchUserReports from "../actions/hooks/useFetchUserReports";
import { useState } from "react";
import ReportsListShort from "../components/reports/ReportsListShort";
import ExpenseCreateOptions from "../components/expenses/ExpenseCreateOptions";
export default function IndexPage() {
  const reportsResponse = useFetchUserReports();
  const [dropdown, setDropdown] = useState("");
  const button = (el) => {
    setDropdown(el);
  };
  return (
    <>
      <div>
        <PageTitle title="Home" />
      </div>
      <div>
        <Container>
          <div className="flex items-center justify-between">
            <div className="ml-auto">
              <ExpenseCreateOptions />
            </div>
          </div>
          <div className="border-t border-gray-300 pb-2"></div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <div className="w-full lg:w-3/6 flex-1">
                <Card header="My Reports">
                  {reportsResponse && reportsResponse.data.reports && (
                    <ReportsOverviewShort
                      reports={reportsResponse.data.reports}
                    />
                  )}
                </Card>
              </div>
              <div className="w-full lg:w-3/6 ">
                <Card header="Reports requiring attention">
                  {reportsResponse && reportsResponse.data.reports && (
                    <ReportsListShort reports={reportsResponse.data.reports} />
                  )}
                </Card>
              </div>
            </div>
            <Card header="Reports by date" headerRight={dropdown}>
              {reportsResponse && (
                <ReportsGraph
                  reports={reportsResponse.data.reports}
                  onLoad={button}
                />
              )}
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
}
