export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="flex flex-col gap-4">
      <ul className="flex flex-row text-sm text-gray-600 gap-8">
        <li>
          <a
            href="https://www.figame.com/cookies-policy-en/"
            target="_blank"
            rel="noopener noreferrer"
            title="Terms"
          >
            Terms and conditions
          </a>
        </li>
        <li>
          <a
            href="https://www.figame.com/privacy-policy-en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
        </li>
        <li>
          <a
            href="https://www.figame.com/anti-phishing-policy-gr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Anti-phishing policy
          </a>
        </li>
        <li>
          <a
            href="https://www.figame.com/safety-of-transactions/"
            target="_blank"
            rel="noopener noreferrer"
            title="Status"
          >
            Safety of transactions
          </a>
        </li>
      </ul>
      <div className="text-sm">
        {currentYear} Figame.com LTD - All rights reserved
      </div>
    </div>
  );
}
