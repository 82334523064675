import useReportUpdate from "../../actions/hooks/useReportUpdate";
import { useEffect, useState } from "react";
import Alert from "../elements/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import InputField from "../layout/InputField";
import { setRerender } from "../../actions/rerenderAction";
import { useDispatch } from "react-redux";
export default function ReportName({ report }) {
  const { reportUpdate, reportUpdateResponse } = useReportUpdate();
  const [tmpReport, setTmpReport] = useState(report);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const dispatch = useDispatch();
  const handleOnBlur = () => {
    if (tmpReport.name) {
      reportUpdate(report.id, tmpReport);
    } else {
      setShowErrorAlert(true);
    }
  };

  useEffect(() => {
    if (reportUpdateResponse) {
      if (reportUpdateResponse.status && reportUpdateResponse.status === 200) {
        setShowSuccessAlert(true);
        dispatch(setRerender());
      } else {
        setShowErrorAlert(true);
      }
    }
  }, [reportUpdateResponse]);

  // Automatically hide alerts
  useEffect(() => {
    const alertTimer = setTimeout(() => {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
    }, 2000);

    return () => {
      clearTimeout(alertTimer);
    };
  }, [showSuccessAlert, showErrorAlert]);

  return (
    <>
      <div className="w-72">
        <InputField
          value={tmpReport.name}
          icon={<FontAwesomeIcon icon={faEdit} />}
          label="report name"
          onBlur={handleOnBlur}
          onChange={(e) => setTmpReport({ ...report, name: e.target.value })}
        />
      </div>
      {showErrorAlert && (
        <Alert
          message="Report name was not updated."
          type="error"
          duration={2000}
        />
      )}
      {showSuccessAlert && (
        <Alert
          message="Report updated successfully."
          type="success"
          duration={2000}
        />
      )}
    </>
  );
}
