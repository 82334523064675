export const triggerAside = () => ({
  type: "TRIGGER_ASIDE",
});

export const openAside = () => ({
  type: "OPEN_ASIDE",
});

export const closeAside = () => ({
  type: "CLOSE_ASIDE",
});
