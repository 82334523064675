// reducers/reportReducer.js
const initialState = {
  status: "open",
  name: `Report ${new Date().toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  })}`,
  from_id: null,
  id: null,
  startDate: null,
  endDate: null,
  expense_id: null,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REPORT":
      return action.payload !== undefined &&
        Object.keys(action.payload).length !== 0
        ? action.payload
        : initialState;
    default:
      return initialState;
  }
};

export default reportReducer;
