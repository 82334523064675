// reducers/expenseReducer.js

const initialState = {
  id: null,
  date: new Date().toISOString().split("T")[0],
  payment_method: "cash",
  user_id: null,
  created_at: null,
  updated_at: null,
  time: null,
  reimbursable: null,
  description: "Description",
  report_id: null,
  state: "unreported",
  merchant: {
    name: "Merchant name",
    phone: null,
    expense_id: null,
    created_at: null,
    updated_at: null,
    address: {
      id: null,
      number: null,
      road: null,
      postal_code: null,
      city: null,
      street_address: null,
      merchant_id: null,
      created_at: null,
      updated_at: null,
    },
  },
  // category_id: null,
  supporting_documents: [],
  items: [],
  price: {
    amount: 0.0,
    currency: "EUR",
  },
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EXPENSE":
      return action.payload !== undefined &&
        Object.keys(action.payload).length !== 0
        ? action.payload
        : initialState;

    default:
      return state;
  }
};

export default expenseReducer;
