import UserForm from "../../components/user/UserForm";
import PageTitle from "../PageTitle";
import Card from "../../components/elements/Card";
import CompanyForm from "../../components/company/CompanyForm";
import Container from "../../components/layout/Container";
import Tabs from "../../components/elements/Tabs";
import TabItem from "../../components/elements/TabItem";
import UsersEdit from "../../components/user/UsersEdit";
import { useState } from "react";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState("Profile");

  return (
    <div>
      <PageTitle title="Settings" />
      <Container>
        <Tabs>
          <TabItem
            label="Profile"
            active={activeTab === "Profile"}
            onClick={() => setActiveTab("Profile")}
          />
          <TabItem
            label="Users"
            active={activeTab === "Users"}
            onClick={() => setActiveTab("Users")}
          />
        </Tabs>

        {activeTab === "Profile" && (
          <>
            <Card header="User profile">
              <UserForm />
            </Card>
            <Card header="Company profile">
              <CompanyForm />
            </Card>
          </>
        )}

        {activeTab === "Users" && <UsersEdit />}
      </Container>
    </div>
  );
};

export default SettingsPage;
