import UsersList from "./UsersList";
import Modal from "../elements/Modal";
import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../actions/modalActions";
import Card from "../elements/Card";
import { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import InviteUser from "./InviteUser";
import Button from "../elements/Button";
import { setRerender } from "../../actions/rerenderAction";
import UsersFilters from "./UsersFilters";
import ToggleFilters from "../expenses/ToggleFilters";

export default function UsersEdit() {
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalType: "inviteUser",
        modalTitle: "Invite a colleague",
        modalBodyData: {},
        key: "inviteUserModal",
      })
    );
  };

  // Check current status of modal
  const modal = useSelector((store) => store.modal);

  // Fetch logged in user
  const { user } = useContext(UserContext);

  // Handle invite users
  const inviteUsers = async () => {
    try {
      await axios.post("user/invite", {
        emails: initialEmails,
        company_id: user.company_id,
      });

      dispatch(setRerender());
    } catch (error) {}
    dispatch(closeModal());
    dispatch(setRerender());
  };

  const [initialEmails, setInitialEmails] = useState([]);
  const onEmailsChange = (emails) => {
    setInitialEmails(emails);
  };

  const [userFiltersIsVisible, setUserFiltersIsVisible] = useState(false);

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <ToggleFilters onChange={setUserFiltersIsVisible} />
        {user.user_roles.includes("administrator") ? (
          <Button label="Invite a user" onClick={handleOpenModal} />
        ) : (
          <div></div>
        )}
      </div>

      {modal.isOpen && modal.key === "inviteUserModal" && (
        <Modal modalSubmit={inviteUsers}>
          <Card>
            <InviteUser
              onEmailsChange={onEmailsChange}
              initialEmails={initialEmails}
            />
          </Card>
        </Modal>
      )}
      <UsersList userFiltersIsVisible={userFiltersIsVisible} />
    </div>
  );
}
