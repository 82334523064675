import { Link } from "react-router-dom";
const LogoWhite = ({ height, width, px, py }) => {
  return (
    <div className="px-6 pb-6">
      <Link to="/">
        <img
          src="/images/logo_expenses_white.png"
          alt="Logo"
          className={`px-${px} py-${py} `}
        />
      </Link>
    </div>
  );
};

export default LogoWhite;
