import { Link, Navigate } from "react-router-dom";
import { useContext, useState } from "react";
import Alert from "../../components/elements/Alert";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import InputField from "../../components/layout/InputField";
import LogoBlue from "../../components/elements/LogoBlue";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const { login, ready, user } = useContext(UserContext);

  async function loginUser(ev) {
    ev.preventDefault();
    await axios
      .post("/user/login", {
        email,
        password,
      })
      .then((response) => {
        login(response.data);
      })
      .catch((error) => {
        setShowErrorAlert(true);
      });
  }

  if ((ready && user))
    return <Navigate to={"/"} />;

  return (
    <div className="grow flex items-center justify-around">
      <div className="mb-64 ">
        <div className="pt-20 pb-4 flex items-center">
          <LogoBlue height="" width="72" />
        </div>
        <h1 className="uppercase text-sm text-center font-bold">Login</h1>
        <form
          className="flex flex-col gap-4 max-w-lg mx-auto"
          onSubmit={loginUser}
        >
          <InputField
            label="Email"
            required
            type="email"
            placeholder="company@email.com"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <InputField
            label="Password"
            required
            type="password"
            placeholder="password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
          />
          <button className="primary">Login</button>
        </form>
        <div className="flex gap-1 text-gray-600 py-2">
          Don't have an account?
          <Link className="underline" to={"/register"}>
            Register now
          </Link>
        </div>
        <div className="flex gap-1 text-gray-600 py-2">
          Forgot my password
          <Link className="underline" to={"/reset"}>
            Reset
          </Link>
        </div>
        {showErrorAlert && (
          <Alert
            message="Login failed, please check your email and password"
            type="error"
            duration={2000}
          />
        )}
      </div>
    </div>
  );
}
