import { closeModal, openModal } from "../../actions/modalActions";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../elements/Modal";
import ReportSelectExpensesForm from "./ReportSelectExpensesForm";
import useReportCreate from "../../actions/hooks/useReportCreate";
import { setRerender } from "../../actions/rerenderAction";
import { useEffect } from "react";
import useReportAddExpense from "../../actions/hooks/useReportAddExpense";
const ReportCreateOptionSelectExpenses = ({ children }) => {
  const { reportCreate, reportCreateResponse } = useReportCreate();
  const dispatch = useDispatch();
  const { reportAddExpense, reportAddExpenseResponse } = useReportAddExpense();
  const modal = useSelector((store) => store.modal);
  const report = useSelector((store) => store.report);

  // functions
  const handleAddExpenseSubmit = () => {
    if (report.expense_id && report.expense_id.length > 0) {
      reportCreate();
    }
  };

  useEffect(() => {
    if (reportCreateResponse !== null && reportCreateResponse.status == 200) {
      reportAddExpense(reportCreateResponse.data.report.id, report);
      dispatch(closeModal({}));
      dispatch(setRerender());
    }
  }, [reportCreateResponse]);

  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalType: "inviteUser",
        modalTitle: `Select from unreported expenses to create a report:`,
        modalBodyData: {},
        key: "addExpense",
      })
    );
  };

  return (
    <>
      <div
        onClick={handleOpenModal}
        className="hover:text-primary-500 hover:cursor-pointer"
      >
        {children}
      </div>
      {modal.isOpen && modal.key === "addExpense" && (
        <Modal modalSubmit={handleAddExpenseSubmit}>
          <ReportSelectExpensesForm />
        </Modal>
      )}
    </>
  );
};

export default ReportCreateOptionSelectExpenses;
