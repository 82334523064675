export default function Table({ headers, body, columnWidths }) {
  if (!headers || !body) {
    return null;
  }

  const th = headers.map((element, index) => {
    return (
      <td
        key={index}
        className="uppercase text-sm font-semibold px-4 py-2"
        style={{ width: columnWidths && columnWidths[index] }}
      >
        {element}
      </td>
    );
  });

  const tbody = body.map((row, row_index) => {
    const isOddRow = row_index % 2 !== 0;

    return (
      <tr className={isOddRow ? "" : ""} key={row_index}>
        {row.map((col, col_index) => (
          <td
            className="px-4 py-2 text-gray"
            key={col_index}
            style={{ width: columnWidths && columnWidths[col_index] }}
          >
            {col}
          </td>
        ))}
      </tr>
    );
  });

  return (
    <div className="overflow-x-auto overflow-y-auto">
      <table className="w-full">
        <thead>
          <tr>{th}</tr>
          <tr>
            <td colSpan={th.length} className="border-t border-gray-300 my-4" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">{tbody}</tbody>
      </table>
    </div>
  );
}
