import axios from "axios";
import { useState, useRef } from "react";
import { startLoader, stopLoader } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";

const useUserStatusUpdate = () => {
  // Variables
  const [userStatusUpdateResponse, setUserStatusUpdateResponse] =
    useState(null);
  const startTimeRef = useRef(null);
  const dispatch = useDispatch();
  // Function to update user status
  const userStatusUpdate = async (id, status) => {
    dispatch(startLoader({}));
    startTimeRef.current = performance.now();
    let hasError = false;
    try {
      const response = await axios.patch(`user/update/${id}/status/${status}`);
      setUserStatusUpdateResponse(response);
    } catch (error) {
      hasError = true;
    } finally {
      const endTime = performance.now();
      const elapsedTime = endTime - startTimeRef.current;
      if (elapsedTime < 500) {
        setTimeout(
          () => dispatch(stopLoader({})),
          hasError ? 4000 : 500 - elapsedTime
        );
      } else {
        dispatch(stopLoader({}));
      }
    }
  };
  return { userStatusUpdate, userStatusUpdateResponse };
};

export default useUserStatusUpdate;
