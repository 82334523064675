const LogoBlue = ({ height, width, px, py }) => {
  return (
    <div className="px-6 pb-6">
      <img
        src="/images/logo_expenses_blue.png"
        alt="Logo"
        className={`px-${px} py-${py} w-${width}`}
      />
    </div>
  );
};

export default LogoBlue;
