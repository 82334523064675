import StatePill from "../../components/expenses/StatePill";
import { useState, useEffect } from "react";
import states from "../../States"; // to be changed with dynamic array from DB when ready
import {
  faFile,
  faCalendar,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../layout/InputField";
import Checkbox from "../elements/Checkbox";

const ExpensesFilters = ({ onChange }) => {
  const initialState = {
    selectedStates: [
      "unreported",
      "open",
      "processing",
      "approved",
      "reimbursed",
      "closed",
    ],
    startDate: "",
    endDate: "",
  };

  // Variables
  const [filterState, setFilterState] = useState(initialState);

  useEffect(() => {
    onChange(filterState);
  }, [filterState]);

  // Handle filter state checkboxes change
  const handleFilterCheckboxChange = (state) => {
    setFilterState((prevState) => {
      if (prevState.selectedStates.includes(state)) {
        return {
          ...prevState,
          selectedStates: prevState.selectedStates.filter((s) => s !== state),
        };
      } else {
        return {
          ...prevState,
          selectedStates: [...prevState.selectedStates, state],
        };
      }
    });
  };

  // Handle filter start date change
  const handleStartDateChange = (e) => {
    setFilterState((prevState) => ({
      ...prevState,
      startDate: e.target.value,
    }));
  };

  // Handle filter end date change
  const handleEndDateChange = (e) => {
    setFilterState((prevState) => ({ ...prevState, endDate: e.target.value }));
  };

  const ExpensesFiltersWrapper = ({ children }) => {
    return (
      <>
        <div className="flex flex-col px-2 mx-2 mt-2">
          <div className="flex flex-row items-center gap-2">{children}</div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <ExpensesFiltersWrapper>
        <FontAwesomeIcon
          icon={faRotateLeft}
          className="text-primary-700 pr-2 text-xl"
          title="Reset filters"
        />
        <div onClick={() => setFilterState(initialState)}>
          <span className="text-primary-700 hover:cursor-pointer hover:text-primary-600 text-base">
            Reset filters
          </span>
        </div>
      </ExpensesFiltersWrapper>
      <ExpensesFiltersWrapper>
        <FontAwesomeIcon
          icon={faFile}
          className="text-primary-700 pr-2 text-xl"
          title="Select the categories of the expenses you want to display"
        />
        <div className="flex flex-wrap gap-2">
          {states.map((state) => (
            <div
              onClick={() => handleFilterCheckboxChange(state)}
              key={state}
              className="flex items-center cursor-pointer"
            >
              <Checkbox
                type="checkbox"
                checked={filterState.selectedStates.includes(state)}
                onChange={() => {}}
              />
              <StatePill state={state} />
            </div>
          ))}
        </div>
      </ExpensesFiltersWrapper>
      <ExpensesFiltersWrapper>
        <FontAwesomeIcon
          icon={faCalendar}
          className="text-primary-700 pr-2 text-xl"
          title="Select dates to display the expenses in this range"
        />

        <div className="flex flex-col w-full sm:w-inherit sm:flex-row gap-2">
          <InputField
            type="date"
            onChange={handleStartDateChange}
            value={filterState.startDate}
          />
          <InputField
            type="date"
            onChange={handleEndDateChange}
            value={filterState.endDate}
          />
        </div>
      </ExpensesFiltersWrapper>
    </div>
  );
};

export default ExpensesFilters;
