import React, { useEffect } from "react";
import Card from "../elements/Card";
import Avatar from "./Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPenToSquare,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import useUserStatusUpdate from "../../actions/hooks/useUserStatusUpdate";
import { setRerender } from "../../actions/rerenderAction";
import { useDispatch } from "react-redux";

const UserItem = ({ data, onClick }) => {
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const { userStatusUpdate, userStatusUpdateResponse } = useUserStatusUpdate();

  // Make user archived
  const handleArchiveUser = (user_id) => {
    userStatusUpdate(user_id, "archived");
  };

  // Make user active
  const handleRemoveArchive = (user_id) => {
    userStatusUpdate(user_id, "active");
  };

  useEffect(() => {
    if (userStatusUpdateResponse) {
      dispatch(setRerender());
    }
  }, [userStatusUpdateResponse]);

  return (
    <div className="hover:cursor-pointer">
      <div className="flex flex-col lg:flex-row items-center justify-between px-4">
        {/* Avatar & first_name / last_name / email */}
        <div className="flex items-center gap-4">
          <div className="max-w-44 min-w-44">
            <Avatar user={data} />
          </div>
          <div className="flex lg:flex-col gap-1">
            <p className="font-bold">{data.first_name}</p>
            <p className="font-bold">{data.last_name}</p>
            <p>{data.email}</p>
          </div>
        </div>

        {/* Role & actions */}
        <div className="flex justify-between lg:pt-0 pt-5 items-center">
          <div className="flex flex-col items-center">
            <span className="text-gray-500 text-sm font-semibold">ROLE</span>{" "}
            <span className="text-sm font-semibold">
              {data.user_roles.includes("administrator") ? (
                <span>ADMINISTRATOR</span>
              ) : (
                <span>USER</span>
              )}
            </span>
          </div>

          <div className="flex px-5 gap-5">
            {user.user_roles.includes("administrator") ? (
              <>
                <div className="group" onClick={() => onClick(data.id)}>
                  <span className="text-gray-500 group-hover:text-primary-600 text-sm font-semibold">
                    EDIT
                  </span>{" "}
                  <FontAwesomeIcon
                    className="text-gray-500 group-hover:text-primary-600"
                    icon={faPenToSquare}
                  />
                </div>

                {data.status === "active" && (
                  <div
                    className="group"
                    onClick={() => handleArchiveUser(data.id)}
                  >
                    <span className="text-gray-500 group-hover:text-red-500 text-sm font-semibold">
                      ARCHIVE
                    </span>{" "}
                    <FontAwesomeIcon
                      className="text-gray-500 group-hover:text-red-500"
                      icon={faTrash}
                    />
                  </div>
                )}

                {data.status === "archived" && (
                  <div
                    className="group"
                    onClick={() => handleRemoveArchive(data.id)}
                  >
                    <span className="text-gray-500 group-hover:text-red-500 text-sm font-semibold">
                      ACTIVATE
                    </span>{" "}
                    <FontAwesomeIcon
                      className="text-gray-500 group-hover:text-red-500"
                      icon={faRotateLeft}
                    />
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserItem;
