import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { openModal, closeModal } from "../../modalActions";
import { setRerender } from "../../rerenderAction";
import { setExpense } from "../../expenseAction";
import useExpenseUpdate from "../useExpenseUpdate";
import { useSelector } from "react-redux";
import useExpenseState from "../useExpenseState";

const useExpenseEditModal = () => {
  const modal = useSelector((store) => store.modal);
  const expense = useSelector((store) => store.expense);
  const dispatch = useDispatch();

  const { expenseState } = useExpenseState();

  // Handle expense updates
  const { expenseUpdate, expenseUpdateResponse } = useExpenseUpdate();
  const modalSubmit = () => {
    expenseUpdate(expense.id, expense);
  };

  // Handle opening the modal to edit an expense
  const handleOpenExpenseModal = (expense) => {
    dispatch(setExpense(expense));

    dispatch(
      openModal({
        modalType: "static",
        modalTitle: "Edit Expense",
        key: "editExpense",
        needsDelete: true,
      })
    );
  };

  const modalDelete = () => {
    expenseState(expense.id, { state: "deleted" });
    dispatch(setRerender());
    dispatch(closeModal());
  };

  // Handle modal close after expense update
  useEffect(() => {
    dispatch(setRerender());
    dispatch(closeModal());
  }, [expenseUpdateResponse]);

  return { handleOpenExpenseModal, modalSubmit, modalDelete, modal };
};

export default useExpenseEditModal;
