import Card from "../elements/Card";
import ReportCreateOptionSelectExpenses from "./ReportCreateOptionSelectExpenses";
export default function ReportNotFound() {
  return (
    <Card>
      <div className="flex justify-center items-center py-16 px-16">
        <div className="flex flex-col items-center border rounded-lg p-6">
          <span className="font-bold text-xl">
            Current filters show you have no reports
          </span>
          <ReportCreateOptionSelectExpenses>
            <div className="flex flex-col items-center gap-4 py-4">
              <div className="w-64">
                <img
                  src="/images/receipt1024.png"
                  alt="image"
                  className="rounded-md w-full"
                />
              </div>
              <span className="hover:text-primary-400 font-semibold">
                Create a new report
              </span>
            </div>
          </ReportCreateOptionSelectExpenses>
        </div>
      </div>
    </Card>
  );
}
