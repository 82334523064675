import { useState } from "react";
import axios from "axios";

const useExpenseState = () => {
  const [expenseStateResponse, setExpenseStateResponse] = useState();

  const expenseState = async (id, state) => {
    try {
      const response = await axios.patch(`expense/state/${id}`, state);
      if (response) {
        setExpenseStateResponse(response);
      }
    } catch (error) {
      // Do nothing
    }
  };

  return { expenseState, expenseStateResponse };
};

export default useExpenseState;
