import { useDispatch, useSelector } from "react-redux";
import { openModal, closeModal } from "../../actions/modalActions";
import useShowAlert from "../../actions/hooks/utility/useShowAlert";
import Modal from "../elements/Modal";
import { setRerender } from "../../actions/rerenderAction";
import ReportFormRange from "./ReportFormRange";
import useReportCreate from "../../actions/hooks/useReportCreate";
import useReportAddExpense from "../../actions/hooks/useReportAddExpense";
import { useEffect, useState } from "react";
import Alert from "../elements/Alert";
const ReportCreateOptionDateRange = ({ children }) => {
  const dispatch = useDispatch();
  const modal = useSelector((store) => store.modal);
  const report = useSelector((store) => store.report);
  const { reportCreate, reportCreateResponse } = useReportCreate();
  const { reportAddExpense, reportAddExpenseResponse } = useReportAddExpense();
  const [alertMessage, setAlertMessage] = useState(null);

  // Handle modal
  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalType: "static",
        modalTitle: "New Report",
        modalSubmit: "SUBMIT_REPORT",
        key: "modalReportSubmit",
        needsDelete: false,
      })
    );
  };

  // Handle Alerts
  const {
    showSuccessAlert,
    setShowSuccessAlert,
    showErrorAlert,
    setShowErrorAlert,
  } = useShowAlert();

  const modalSubmit = () => {
    if (report.expense_id && report.expense_id.length > 0) {
      reportCreate();
    } else {
      setAlertMessage("Failed to create report. No expenses in this range.");
      setShowErrorAlert(true);
    }
  };

  useEffect(() => {
    if (reportCreateResponse !== null && reportCreateResponse.status == 200) {
      reportAddExpense(reportCreateResponse.data.report.id, report);
      dispatch(closeModal({}));
      dispatch(setRerender());
    }
  }, [reportCreateResponse]);

  useEffect(() => {
    if (
      reportAddExpenseResponse !== null &&
      reportAddExpenseResponse.status == 200
    ) {
      setAlertMessage("Report created succesfully.");
      setShowSuccessAlert(true);
    }
  }, [reportAddExpenseResponse]);

  return (
    <>
      <div
        onClick={handleOpenModal}
        className="hover:text-primary-500 hover:cursor-pointer"
      >
        {children}
        {showErrorAlert && (
          <Alert message={alertMessage} type="error" duration={2000} />
        )}
        {showSuccessAlert && (
          <Alert message={alertMessage} type="success" duration={2000} />
        )}
      </div>
      {modal.isOpen && modal.key === "modalReportSubmit" && (
        <Modal modalSubmit={modalSubmit}>
          <ReportFormRange />
        </Modal>
      )}
    </>
  );
};

export default ReportCreateOptionDateRange;
