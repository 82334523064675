import useFetchExpenses from "../../actions/hooks/useFetchExpenses";
import ExpenseItem from "../expenses/ExpenseItem";
import Checkbox from "../elements/Checkbox";
import ExpenseNotFound from "../expenses/ExpenseNotFound";
const ReportAddExpenseList = ({ reportNewExpenses, setReportNewExpenses }) => {
  const expensesArray = useFetchExpenses();
  const filteredExpenses = expensesArray.filter(
    (expense) => expense.state === "unreported"
  );

  const handleCheckboxChange = (expense) => {
    const expenseId = expense.id;

    const index = reportNewExpenses.indexOf(expenseId);
    if (index !== -1) {
      // Expense id exists in reportNewExpenses, remove it
      const updatedExpenses = [...reportNewExpenses];
      updatedExpenses.splice(index, 1);
      setReportNewExpenses(updatedExpenses);
    } else {
      // Expense id doesn't exist, append it
      setReportNewExpenses([...reportNewExpenses, expenseId]);
    }
  };

  return (
    <>
      <div className="h-[400px] overflow-y-auto">
        <ul className="flex flex-col gap-4">
          {filteredExpenses.map((expense, index) => (
            <li key={index}>
              <div
                className={`px-4 py-4 bg-white shadow-sm border rounded-md  border-2`}
              >
                <Checkbox
                  onChange={() => {
                    handleCheckboxChange(expense);
                  }}
                />
                <ExpenseItem expense={expense} onCheckChange={() => {}} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ReportAddExpenseList;
