import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Alert = ({ message, type, duration = 3000 }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  if (!isVisible) {
    return <></>;
  }

  let alertClasses = "fixed bottom-0 right-0 m-4 p-4 text-white rounded shadow flex items-center ";

  // Define dynamic classes based on the 'type' prop
  switch (type) {
    case "success":
      alertClasses += "bg-green-600";
      break;
    case "error":
      alertClasses += "bg-red-800";
      break;
    case "warning":
      alertClasses += "bg-yellow-500";
      break;
    default:
      alertClasses += "bg-red-800";
      break;
  }

  return (
    <div className={`${alertClasses} z-40`}>
      <span className="mr-2">{message}</span>
      <button onClick={() => setIsVisible(false)} className="text-white">
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default Alert;
