import Avatar from "../user/Avatar";
import FormattedDate from "../elements/FormattedDate";
import { useEffect, useState } from "react";
import useFetchComments from "../../actions/hooks/useFetchComments";

const CommentsList = ({ report_id, rerender, onChangeHeight }) => {
  const [comments, setComments] = useState([]);
  const { fetchComments, fetchCommentsResponse } = useFetchComments();

  // Fetch comments when component renders
  useEffect(() => {
    fetchComments(report_id);
  }, [rerender]);

  // When new comments are fetched successfully, add them to state and rerender
  useEffect(() => {
    if (fetchCommentsResponse && fetchCommentsResponse.status === 200) {
      setComments(fetchCommentsResponse.data.comments);
      onChangeHeight();
    }
  }, [fetchCommentsResponse, setComments]);

  return comments.map((comment, index) => {
    return (
      <div key={index}>
        <div className="flex gap-4 items-center">
          <Avatar user={comment.user} hiddenTitle />
          <div className="flex flex-col overflow-x-auto">
            <span className="font-semibold">
              {comment.user.first_name} {comment.user.last_name}
            </span>
            <span>{comment.message}</span>
            <FormattedDate
              className="text-xs"
              date={comment.created_at}
              format={{
                hour: "numeric",
                minute: "numeric",
                month: "short",
                day: "numeric",
              }}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default CommentsList;
