import { useEffect, useState } from "react";

const useShowAlert = () => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // Automatically hide alerts
  useEffect(() => {
    const alertTimer = setTimeout(() => {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
    }, 2000);

    return () => {
      clearTimeout(alertTimer);
    };
  }, [showSuccessAlert, showErrorAlert]);

  return {
    showSuccessAlert,
    setShowSuccessAlert,
    showErrorAlert,
    setShowErrorAlert,
  };
};

export default useShowAlert;
