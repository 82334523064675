import { useContext, useEffect, useState } from "react";
import InputField from "../layout/InputField";
import { UserContext } from "../../context/UserContext";
import Alert from "../elements/Alert";
import axios from "axios";
import Avatar from "../user/Avatar";
import Button from "../elements/Button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setRerender } from "../../actions/rerenderAction";
import LoadError from "../elements/LoadError";

export default function UserForm({ userId }) {
  const dispatch = useDispatch();
  const [userForm, setUserForm] = useState({
    first_name: "",
    last_name: "",
  });

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    try {
      let query = "user";
      if (userId) {
        query = "user/" + userId;
      }
      const response = await axios.get(query);
      setUserForm(response.data.user);
      setLoading(false);
    } catch (error) {
      // Do nothing
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    try {
      let query = "user/update";
      if (userId) {
        query = "user/update/" + userId;
      }
      const response = await axios.patch(query, userForm);
      setUserForm(response.data.user);

      if (!userId) {
        setUser(response.data.user);
      }
      setShowSuccessAlert(true);
    } catch (error) {
      setShowErrorAlert(true);
    }

    dispatch(setRerender());
  };

  const rerender = useSelector((store) => store.rerender);
  useEffect(() => {
    fetchUser();
  }, [rerender]);

  useEffect(() => {
    const alertTimer = setTimeout(() => {
      setShowSuccessAlert(false);
      setShowErrorAlert(false);
    }, 2000);

    return () => {
      clearTimeout(alertTimer);
    };
  }, [showSuccessAlert, showErrorAlert]);

  if (loading) {
    return <LoadError />;
  }

  return (
    <div>
      <form
        className="w-full max-w-screen-lg mx-auto p-4"
        onSubmit={handleOnSubmit}
      >
        <div className="flex flex-col md:flex-row md:items-center justify-center md:justify-between">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <div className="max-w-300px mx-auto mb-4">
              <Avatar user={userForm} />
            </div>
            <div className="text-center font-semibold whitespace-nowrap overflow-hidden">
              Notifications will be sent to
            </div>
            <div className="text-center whitespace-nowrap overflow-hidden text-sm">
              {userForm.email}
            </div>
          </div>
          <div className="w-full md:w-2/3">
            <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/2 px-2 mb-4">
                <InputField
                  label="First name"
                  required
                  placeholder="First name"
                  onChange={(e) =>
                    setUserForm({ ...userForm, first_name: e.target.value })
                  }
                  value={userForm.first_name}
                />
              </div>
              <div className="w-full md:w-1/2 px-2 mb-4">
                <InputField
                  label="Last name"
                  required
                  onChange={(e) =>
                    setUserForm({ ...userForm, last_name: e.target.value })
                  }
                  value={userForm.last_name}
                />
              </div>
            </div>
            <div className="flex flex-col items-end">
              <Button label="Update" />
            </div>
          </div>
        </div>
      </form>

      {showErrorAlert && (
        <Alert
          message="Failed to update user details."
          type="error"
          duration={2000}
        />
      )}
      {showSuccessAlert && (
        <Alert
          message="User updated successfully."
          type="success"
          duration={2000}
        />
      )}
    </div>
  );
}
